export const convertIScenarioToCompareArrayToScenarioCompareRequest = (scenarios) => {
    const scenarioCompareRequest = {};
    scenarios.forEach((scenario, index) => {
        var _a, _b;
        scenarioCompareRequest[`Scenarios[${index}].scenarioId`] =
            scenario.identity;
        scenario.projectsToHighlight.forEach((project, projectIndex) => {
            scenarioCompareRequest[`Scenarios[${index}].projectsToHighlight[${projectIndex}]`] = project;
        });
        scenarioCompareRequest[`Scenarios[${index}].description`] =
            scenario.description;
        scenarioCompareRequest[`Scenarios[${index}].photo.fileName`] =
            (_a = scenario.photo) === null || _a === void 0 ? void 0 : _a.fileName;
        scenarioCompareRequest[`Scenarios[${index}].photo.file`] =
            (_b = scenario.photo) === null || _b === void 0 ? void 0 : _b.file;
    });
    return scenarioCompareRequest;
};
export const convertPlanCompareResponseToIScenarioComparison = (scenario) => {
    return {
        scenarios: scenario.scenarios.map(convertScenarioCompareResponseToIScenarioCompareDetails),
        overlappingProjects: mapProjectCompareResponseToIProjectCompareDetails(scenario.overlappingProjects),
    };
};
export const convertScenarioCompareResponseToIScenarioCompareDetails = (scenario) => {
    var _a, _b, _c;
    return {
        scenarioId: scenario.scenarioId,
        scenarioName: scenario.scenarioName,
        isLocked: scenario.isLocked,
        description: scenario.description,
        photoUrl: scenario.photoFileUrl,
        projectCount: scenario.projectCount,
        totalAllocatedBudget: (_a = scenario.totalAllocatedBudget) !== null && _a !== void 0 ? _a : undefined,
        averageYearlyBudget: (_b = scenario.averageYearlyBudget) !== null && _b !== void 0 ? _b : undefined,
        netAllocatedBudget: (_c = scenario.netAllocatedBudget) !== null && _c !== void 0 ? _c : undefined,
        priorities: scenario.priorities.map(convertPlanPriorityCompareResponseToIPlanPriorityCompare),
        fundingTypeAllocations: convertPlanFundingTypeCompareResponseToIFundingTypeAllocation(scenario.fundingTypeAllocations),
        uniqueProjects: mapProjectCompareResponseToIProjectCompareDetails(scenario.uniqueProjects),
        projectTypeBudget: scenario.projectTypeBudget.map(convertPlanProjectTypeCompareResponseToIProjectTypeBudget),
        // TODO: Add the rest of the properties as needed
    };
};
export const convertPlanProjectTypeCompareResponseToIProjectTypeBudget = (projectType) => {
    var _a;
    return {
        projectType: projectType.projectType,
        allocatedAmount: (_a = projectType.allocatedAmount) !== null && _a !== void 0 ? _a : undefined,
    };
};
export const convertPlanPriorityCompareResponseToIPlanPriorityCompare = (priorities) => {
    return {
        priorityRating: priorities.priorityRating,
        byFacility: priorities.byFacility.map(convertFacilityPriorityCompareResponseToIFacilityPriorityCompare),
        byProjectType: priorities.byProjectType.map(convertProjectTypePriorityCompareResponseToIProjectTypePriorityCompare),
    };
};
export const convertFacilityPriorityCompareResponseToIFacilityPriorityCompare = (facility) => {
    var _a;
    return {
        facilityId: facility.facilityId,
        facilityName: facility.facilityName,
        allocatedAmount: (_a = facility.allocatedAmount) !== null && _a !== void 0 ? _a : undefined,
    };
};
export const convertProjectTypePriorityCompareResponseToIProjectTypePriorityCompare = (facility) => {
    var _a;
    return {
        projectType: facility.projectType,
        allocatedAmount: (_a = facility.allocatedAmount) !== null && _a !== void 0 ? _a : undefined,
    };
};
export const convertPlanFundingTypeCompareResponseToIFundingTypeAllocation = (fundingTypeAllocations) => {
    return fundingTypeAllocations.map((fundingTypeAllocation) => {
        var _a;
        return {
            fundingTypeId: fundingTypeAllocation.fundingTypeId,
            fundingTypeName: fundingTypeAllocation.fundingTypeName,
            allocation: (_a = fundingTypeAllocation.budgetAllocation) !== null && _a !== void 0 ? _a : undefined,
            planYear: fundingTypeAllocation.planYear,
        };
    });
};
const mapProjectCompareResponseToIProjectCompareDetails = (uniqueProjects) => {
    return Object.fromEntries(Object.entries(uniqueProjects).map(([key, projects]) => [
        key,
        projects.map(convertProjectCompareResponseToIProjectCompareDetails),
    ]));
};
export const convertProjectCompareResponseToIProjectCompareDetails = (project) => {
    var _a, _b;
    return {
        identity: project.projectId,
        name: project.projectName,
        estimatedBudget: (_a = project.estimatedBudget) !== null && _a !== void 0 ? _a : undefined,
        priority: (_b = project.projectPriority) !== null && _b !== void 0 ? _b : undefined,
    };
};
