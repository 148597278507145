import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Spinner, useDisclosure } from '@chakra-ui/react';
import { usePlannerPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useFacilityList } from '@frontend/domain/contexts/Facility/FacilityListHook';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { AddProjectModal } from './AddProjectModal/AddProjectModal';
import ProjectsTable from './ProjectsTable';
import { ProjectInstructions } from './ProjectsInstructions';
export const priorityPadding = '0px';
export const Projects = () => {
    const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
    const { facilities } = useFacilityList(orgIdentity);
    const location = useLocation();
    const navigate = useNavigate();
    const { orgFundingTypes, orgProjectTypes } = usePlannerSettingsContext();
    const { isLoading, projectList, newProjectAdded, toggleNewProjectAdded, addNewProject, } = useProjectsContext();
    const projectDisclosure = useDisclosure();
    const { canEdit, canAddScenario, canAddProject } = usePlannerPermissions(projectList);
    useEffect(() => {
        if (location.state && location.state.showModal) {
            projectDisclosure.onOpen();
            // clear state once used to avoid state mutation issues on reload
            window.history.replaceState({}, document.title);
            location.state.showModal = false;
        }
    });
    return (_jsx(PageLayout, Object.assign({ title: 'myProjects', canAddProject: canAddProject, canAddScenario: canAddScenario, onAddProject: projectDisclosure.onOpen, onAddScenario: (orgIdentity) => navigate(AppRoutes.myScenarios(orgIdentity), {
            state: { showModal: true },
        }), canEdit: canEdit }, { children: _jsxs(_Fragment, { children: [isLoading || orgIdentity === undefined ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : projectList.length > 0 ? (_jsx(ProjectsTable, { projects: projectList, newProjectAdded: newProjectAdded, toggleNewProjectAdded: toggleNewProjectAdded, canEdit: canEdit, orgIdentity: orgIdentity })) : (_jsx(ProjectInstructions, { canAddProject: canAddProject, hasNoProjects: projectList.length === 0, canEdit: canEdit })), _jsx(AddProjectModal, { disclosure: projectDisclosure, facilities: facilities, fundingTypes: orgFundingTypes, projectTypes: orgProjectTypes, onCreate: addNewProject })] }) })));
};
