import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Center, Stack, Text } from '@chakra-ui/react';
import { measureColors } from '../../config/constants';
export const LifeRemainingCircle = ({ years, borderWidth, size, }) => {
    const generateColor = (years) => {
        switch (true) {
            case years >= 15:
                return measureColors.MEASURE_EXCELLENT;
            case years >= 5 && years < 15:
                return measureColors.MEASURE_ACCEPTABLE;
            case years >= 0 && years < 5:
                return measureColors.MEASURE_CAUTION;
            case years > -5 && years <= -1:
                return measureColors.MEASURE_ALERT;
            case years <= -5:
                return measureColors.MEASURE_ALARM;
            default:
                return 'white';
        }
    };
    return (_jsx(_Fragment, { children: years !== undefined ? (_jsx(Stack, Object.assign({ direction: 'row', align: 'center' }, { children: _jsx(Center, Object.assign({ boxSize: size === 'sm' ? '15px' : '26px', borderColor: generateColor(years), borderWidth: borderWidth ? borderWidth : '2px', borderRadius: '50%', "data-testid": 'life_Remaining_Circle' }, { children: _jsx(Text, Object.assign({ "data-testid": 'years_value', variant: 'slider', fontSize: size === 'sm' ? '10px' : '12px' }, { children: years })) })) }))) : (_jsx(Text, { children: "Unknown" })) }));
};
