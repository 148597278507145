import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useTheme } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
export const BarChart = ({ config, containerWidth, containerHeight, data, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    const theme = useTheme();
    const hasGroups = data.datasets.length > 1;
    const options = {
        chart: {
            id: 'basic-bar',
            group: config.chartGroup ? config.chartGroup : '',
            stacked: config.stacked ? true : false,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: config.animations || false,
            },
            width: containerWidth,
        },
        colors: config.datasetColors,
        dataLabels: {
            enabled: (_b = (_a = config.dataLabels) === null || _a === void 0 ? void 0 : _a.enable) !== null && _b !== void 0 ? _b : false,
            offsetX: config.horizontal ? -10 : 0,
            offsetY: config.horizontal ? 0 : -20,
            style: {
                colors: [theme.colors.secondary.dark[500]],
                fontWeight: '400',
            },
            formatter: ((_c = config.barLabel) === null || _c === void 0 ? void 0 : _c.format)
                ? (_d = config.barLabel) === null || _d === void 0 ? void 0 : _d.format
                : (val) => val || '',
        },
        legend: {
            // click/hover events of the legend will stop working if you provide custom legend labels
            customLegendItems: ((_e = config.legend) === null || _e === void 0 ? void 0 : _e.datasetLabels)
                ? config.legend.datasetLabels
                : [],
            position: ((_f = config.legend) === null || _f === void 0 ? void 0 : _f.position) || 'bottom',
            show: ((_g = config.legend) === null || _g === void 0 ? void 0 : _g.show) === false ? false : true,
        },
        grid: {
            xaxis: {
                lines: {
                    show: config.horizontal,
                },
            },
            yaxis: {
                lines: {
                    show: !config.horizontal,
                },
            },
        },
        plotOptions: {
            bar: {
                // distributed true allows bars to be formatted individually
                // we only want to format bars individually when we are not grouping or stacking datasets
                distributed: data.datasets.length > 1 ? false : true,
                horizontal: config.horizontal ? true : false,
                dataLabels: {
                    position: ((_h = config.barLabel) === null || _h === void 0 ? void 0 : _h.position) || 'top',
                    total: {
                        enabled: (_k = (_j = config.barLabel) === null || _j === void 0 ? void 0 : _j.enabled) !== null && _k !== void 0 ? _k : hasGroups,
                        offsetY: config.horizontal ? 0 : 15,
                        offsetX: config.horizontal ? 15 : 0,
                        formatter: ((_l = config.barLabel) === null || _l === void 0 ? void 0 : _l.format)
                            ? (_m = config.barLabel) === null || _m === void 0 ? void 0 : _m.format
                            : (val) => val || '',
                    },
                },
            },
        },
        annotations: config.showAvg
            ? config.annotations
            : { xaxis: [], yaxis: [], points: [], images: [] },
        tooltip: {
            enabled: ((_o = config.tooltip) === null || _o === void 0 ? void 0 : _o.enabled) ? true : false,
            shared: (_q = (_p = config.tooltip) === null || _p === void 0 ? void 0 : _p.shared) !== null && _q !== void 0 ? _q : true,
            intersect: (_s = (_r = config.tooltip) === null || _r === void 0 ? void 0 : _r.intersect) !== null && _s !== void 0 ? _s : false,
            custom: (_t = config.tooltip) === null || _t === void 0 ? void 0 : _t.custom,
            x: {
                formatter: (_v = (_u = config.formatters) === null || _u === void 0 ? void 0 : _u.tooltip) === null || _v === void 0 ? void 0 : _v.x,
            },
            y: {
                formatter: (_x = (_w = config.formatters) === null || _w === void 0 ? void 0 : _w.tooltip) === null || _x === void 0 ? void 0 : _x.y,
            },
        },
        xaxis: {
            categories: data.axisCategories,
            labels: {
                style: { colors: (_y = config.labelColors) === null || _y === void 0 ? void 0 : _y.xAxis },
                formatter: (_z = config.formatters) === null || _z === void 0 ? void 0 : _z.xAxis,
                rotate: 0,
                trim: false,
                hideOverlappingLabels: false,
            },
            floating: false,
            // works for horizontal graph even though it has type error (build and test pass),
            // unable to consistently get logical scale for different data values
            forceNiceScale: true,
        },
        yaxis: {
            min: (_0 = config.formatters) === null || _0 === void 0 ? void 0 : _0.min,
            max: (_1 = config.formatters) === null || _1 === void 0 ? void 0 : _1.max,
            labels: {
                minWidth: 100,
                maxWidth: 100,
                style: { colors: (_2 = config.labelColors) === null || _2 === void 0 ? void 0 : _2.yAxis },
                formatter: (_3 = config.formatters) === null || _3 === void 0 ? void 0 : _3.yAxis,
            },
            forceNiceScale: true,
        },
    };
    const series = data.datasets;
    return (_jsx(Box, Object.assign({ width: '100%', height: containerHeight }, { children: _jsx(Chart, { options: options, series: series, type: 'bar', height: '100%', width: '100%' }) })));
};
