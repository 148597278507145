import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
const basePath = '';
export const PlannerRoutes = {
    base: AppPaths.home,
    organization: {
        base: `${basePath}${AppPaths.organization.base}`,
        withOrgId: (orgIdentity) => `${basePath}${AppPaths.organization.withOrgId(orgIdentity)}`,
    },
    projects: {
        base: (orgIdentity) => `${basePath}${AppPaths.projects.base(orgIdentity)}`,
    },
    scenarios: {
        base: (orgIdentity) => `${basePath}${AppPaths.scenarios.base(orgIdentity)}`,
        withScenarioId: (orgIdentity, scenarioIdentity) => `${basePath}${AppPaths.scenarios.withScenarioId({
            orgIdentity: orgIdentity,
            scenarioIdentity: scenarioIdentity,
        })}`,
        compare: (orgIdentity) => `${basePath}${AppPaths.scenarios.compare(orgIdentity)}`,
    },
    assets: {
        base: (orgIdentity) => `${basePath}${AppPaths.assets.base(orgIdentity)}`,
    },
    logout: `${basePath}${AppPaths.logout}`,
    support: `${basePath}${AppPaths.support}`,
};
