import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { usePlanService } from '@frontend/domain/services/PlanService';
import { useState } from 'react';
export const useScenarioCompare = () => {
    const { platformApi } = useDependencies();
    const { plan: planApi } = usePlanService({ platformApi });
    const [comparison, setComparison] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const fetchComparison = (scenariosToCompare) => {
        setIsLoading(true);
        return planApi
            .fetchComparison(scenariosToCompare)
            .then((res) => setComparison(res))
            .finally(() => setIsLoading(false));
    };
    return {
        isLoading,
        comparison,
        fetchComparison,
    };
};
