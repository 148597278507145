import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, useTheme, VStack } from '@chakra-ui/react';
import { ScrollableBarChart } from '@frontend/design-system/components/Graphs/apexcharts/ScrollableBarChart';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import Heading1 from '@frontend/design-system/components/Typography/Heading1/Heading1';
import { getAvgAnnotation, getGlobalMinMax, } from '@frontend/design-system/utils/chartsUtils';
import { getCurrencyNotation } from '@frontend/design-system/utils/uiUtils';
import { nonBrandedColorsList } from '@frontend/shared/src/design/colors';
import React from 'react';
import { WidgetType } from '../../../contexts/Plans/WidgetHook';
export const CompareFundingAllocatedByYear = ({ baseScenario, comparisonScenario, baseScenarioSummary, comparisonScenarioSummary, }) => {
    const theme = useTheme();
    const fundingColorMap = new Map();
    let colorIndex = 0;
    const assignColors = (scenario) => {
        var _a;
        const assignedColors = [];
        (_a = scenario === null || scenario === void 0 ? void 0 : scenario.fundingTypeAllocations) === null || _a === void 0 ? void 0 : _a.forEach((fundingTypeAllocation) => {
            var _a;
            const fundingName = fundingTypeAllocation.fundingTypeName;
            if (!fundingColorMap.has(fundingName)) {
                const color = nonBrandedColorsList[colorIndex % nonBrandedColorsList.length];
                fundingColorMap.set(fundingName, color);
                colorIndex++;
            }
            assignedColors.push((_a = fundingColorMap.get(fundingName)) !== null && _a !== void 0 ? _a : theme.colors.gray[400]);
        });
        return assignedColors;
    };
    if (baseScenario) {
        assignColors(baseScenario);
    }
    if (comparisonScenario) {
        assignColors(comparisonScenario);
    }
    const displayChart = (scenario) => {
        var _a, _b, _c;
        const years = [];
        if (baseScenarioSummary &&
            scenario.scenarioId === (baseScenarioSummary === null || baseScenarioSummary === void 0 ? void 0 : baseScenarioSummary.scenarioId)) {
            years.push(...Array.from({ length: baseScenarioSummary.duration }, (_, i) => baseScenarioSummary.startYear + i));
        }
        else if (comparisonScenarioSummary &&
            scenario.scenarioId === (comparisonScenarioSummary === null || comparisonScenarioSummary === void 0 ? void 0 : comparisonScenarioSummary.scenarioId)) {
            years.push(...Array.from({ length: comparisonScenarioSummary.duration }, (_, i) => (comparisonScenarioSummary === null || comparisonScenarioSummary === void 0 ? void 0 : comparisonScenarioSummary.startYear) + i));
        }
        const fundTypeByYearDataset = [];
        const nameSet = new Set();
        (_a = scenario === null || scenario === void 0 ? void 0 : scenario.fundingTypeAllocations) === null || _a === void 0 ? void 0 : _a.forEach((fundingTypeAllocation) => {
            if (!nameSet.has(fundingTypeAllocation.fundingTypeName)) {
                nameSet.add(fundingTypeAllocation.fundingTypeName);
                fundTypeByYearDataset.push({
                    name: fundingTypeAllocation.fundingTypeName,
                    data: years.map((year) => {
                        var _a;
                        const budgetData = (_a = scenario === null || scenario === void 0 ? void 0 : scenario.fundingTypeAllocations) === null || _a === void 0 ? void 0 : _a.find((item) => item.planYear === year &&
                            item.fundingTypeName === fundingTypeAllocation.fundingTypeName);
                        return (budgetData === null || budgetData === void 0 ? void 0 : budgetData.allocation) ? budgetData === null || budgetData === void 0 ? void 0 : budgetData.allocation : 0;
                    }),
                });
            }
        });
        const fundingTypeByYearData = {
            axisCategories: years.map((year) => year.toString()),
            datasets: fundTypeByYearDataset,
        };
        const fundingTypeNamesInPlan = (scenario === null || scenario === void 0 ? void 0 : scenario.fundingTypeAllocations)
            ? [
                ...new Set(scenario === null || scenario === void 0 ? void 0 : scenario.fundingTypeAllocations.map((item) => item.fundingTypeName)),
            ].sort((a, b) => a.localeCompare(b))
            : [];
        const { globalMax, globalMin } = getGlobalMinMax(fundingTypeByYearData);
        const datasetColors = fundTypeByYearDataset.map((dataset) => fundingColorMap.get(dataset.name) || theme.colors.gray[400]);
        const config = {
            chartType: 'bar',
            widgetType: WidgetType.FUNDING_TYPE_YEAR,
            chartData: {
                config: {
                    stacked: true,
                    showAvg: fundTypeByYearDataset.length > 0 ? true : false,
                    barLabel: {
                        enabled: false,
                    },
                    annotations: fundTypeByYearDataset.length > 0
                        ? getAvgAnnotation('vertical', fundingTypeByYearData)
                        : {},
                    tooltip: {
                        enabled: true,
                        shared: false,
                        intersect: true,
                    },
                    legend: {
                        // Use custom labels for hover functionality when only one item is displayed.
                        datasetLabels: fundingTypeNamesInPlan.length === 1 ? fundingTypeNamesInPlan : [],
                    },
                    formatters: {
                        min: globalMin,
                        max: globalMax,
                        yAxis: fundingTypeByYearData.datasets.length > 0
                            ? getCurrencyNotation
                            : (value) => {
                                if (value === 0 || value === 1 || value === 2) {
                                    return `$${value.toFixed(0)}`;
                                }
                                return `$${value.toFixed(1)}`;
                            },
                    },
                    datasetColors: fundingTypeNamesInPlan.length === 1
                        ? [datasetColors[0]]
                        : datasetColors,
                },
                data: fundingTypeByYearData,
            },
        };
        return (_jsx(ScrollableBarChart, { data: (_b = config.chartData.data) !== null && _b !== void 0 ? _b : {}, config: (_c = config.chartData.config) !== null && _c !== void 0 ? _c : {}, containerHeight: '100%', containerWidth: '100%', chunkSize: 10 }));
    };
    return (_jsxs(VStack, Object.assign({ w: '100%', display: 'flex', justifyContent: 'center', gap: 8 }, { children: [_jsxs(VStack, Object.assign({ gap: 1 }, { children: [_jsx(Heading1, Object.assign({ color: 'primary.blue.500' }, { children: "Total Funding Dollars Allocated Per Year" })), _jsx(Body, Object.assign({ color: 'primary.blue.500' }, { children: "Breakdown of each funding type and how it's allocated every year. Average of yearly funding." }))] })), _jsxs(HStack, Object.assign({ alignItems: 'stretch', w: '100%', gap: 0, h: '309px' }, { children: [_jsx(Box, Object.assign({ w: comparisonScenario ? '50%' : '100%', paddingX: 8 }, { children: baseScenario && displayChart(baseScenario) })), comparisonScenario ? (_jsx(Box, Object.assign({ borderLeft: '2px', borderColor: 'secondary.dark.100', w: '50%', paddingX: 8 }, { children: displayChart(comparisonScenario) }))) : null] }))] })));
};
