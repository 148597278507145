import { jsx as _jsx } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { PlannerInstructionsContainer } from '../PlannerInstructions';
export const ProjectInstructions = ({ canAddProject, hasNoProjects, canEdit, }) => {
    const getProjectInstructions = () => {
        if (canAddProject) {
            if (hasNoProjects) {
                return 'Click the "Add Project" button to create a new project';
            }
        }
        else if (canEdit) {
            // No funding or project type defined
            return 'To be able to add Projects, go to Organization Settings --> myPlanning and enter in values for funding type and project type';
        }
        else {
            return 'There are no projects to view for this organization';
        }
        return '';
    };
    return (_jsx(VStack, Object.assign({ justifyContent: 'center', h: '60vh' }, { children: _jsx(PlannerInstructionsContainer, { instructions: getProjectInstructions() }) })));
};
