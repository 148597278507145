import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, VStack } from '@chakra-ui/react';
import Heading1 from '@frontend/design-system/components/Typography/Heading1/Heading1';
import PreTitleStrong from '@frontend/design-system/components/Typography/PreTitleStrong/PreTitleStrong';
import { getCurrencyNotation } from '@frontend/design-system/utils/uiUtils';
import React from 'react';
export const CompareAllocatedBudget = ({ baseScenario, comparisonScenario, }) => {
    const displayAllocatedBudget = (scenario) => {
        if (scenario.projectCount === 0) {
            return 'Scenario is Empty';
        }
        else if (scenario.projectCount > 0 &&
            !scenario.totalAllocatedBudget &&
            scenario.totalAllocatedBudget !== 0) {
            return 'No Budget available';
        }
        else if ((scenario === null || scenario === void 0 ? void 0 : scenario.totalAllocatedBudget) ||
            (scenario === null || scenario === void 0 ? void 0 : scenario.totalAllocatedBudget) === 0) {
            return getCurrencyNotation(scenario === null || scenario === void 0 ? void 0 : scenario.totalAllocatedBudget, true, 1);
        }
        else {
            return '';
        }
    };
    return (_jsxs(VStack, Object.assign({ w: '100%', display: 'flex', justifyContent: 'center', gap: 8 }, { children: [_jsx(Heading1, Object.assign({ color: 'primary.blue.500' }, { children: "Total Allocated Budget" })), _jsxs(HStack, Object.assign({ alignItems: 'stretch', w: comparisonScenario ? '100%' : '60%', gap: 0 }, { children: [_jsx(PreTitleStrong, Object.assign({ flex: 1, paddingX: 10, display: 'flex', justifyContent: 'center', "data-testid": 'baseScenarioAllocatedBudget' }, { children: baseScenario && displayAllocatedBudget(baseScenario) })), comparisonScenario ? (_jsx(PreTitleStrong, Object.assign({ display: 'flex', flex: 1, justifyContent: 'center', borderLeft: '2px', borderColor: 'secondary.dark.100', paddingX: 10, "data-testid": 'comparisonScenarioAllocatedBudget' }, { children: displayAllocatedBudget(comparisonScenario) }))) : null] }))] })));
};
