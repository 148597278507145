import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Center, Grid, GridItem, HStack, IconButton, Spacer, Stack, Text, useToken, VStack, } from '@chakra-ui/react';
import { PieChart } from '@frontend/design-system/components/Graphs/apexcharts/PieChart';
import { ScrollableBarChart } from '@frontend/design-system/components/Graphs/apexcharts/ScrollableBarChart';
import { formatBudgetToString } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import { BudgetTypeEnum, PlanningFactors, } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import { useEffect, useRef, useState } from 'react';
import useWidgetData, { WidgetType } from '../../contexts/Plans/WidgetHook';
var WidgetCategoryEnum;
(function (WidgetCategoryEnum) {
    WidgetCategoryEnum[WidgetCategoryEnum["FACILITY"] = 0] = "FACILITY";
    WidgetCategoryEnum[WidgetCategoryEnum["BUDGET"] = 1] = "BUDGET";
})(WidgetCategoryEnum || (WidgetCategoryEnum = {}));
const defaultWidgets = [
    WidgetType.FACILITY,
    WidgetType.TOTAL_BUDGET,
];
export const WidgetContainer = ({ fundingTypesList, budgetType, planData, planningFactor, scenarioId, years, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const [index, setIndex] = useState(0);
    const [widgetTypes, setWidgetTypes] = useState(defaultWidgets);
    const configs = useWidgetData(planData, widgetTypes, fundingTypesList, years);
    const [blue, light_blue, secOrange, white, gray] = useToken('colors', [
        'brand.primBlue',
        'primBlue.60',
        'brand.secOrange',
        'base.white',
        'secondaryDarkGray.800',
    ]);
    useEffect(() => {
        setIndex(0);
    }, [scenarioId]);
    // Need to handle logic to make continuous loop through widgets
    const showPrevious = () => {
        if (index > 0) {
            setIndex((prev) => prev - 1);
        }
    };
    const showNext = () => {
        if (index < configs.length - 1) {
            setIndex((prev) => prev + 1);
        }
    };
    const determineWidgetOrder = (planningFactor) => {
        switch (planningFactor) {
            case PlanningFactors.FUNDING_TYPE: {
                return [WidgetCategoryEnum.BUDGET, WidgetCategoryEnum.FACILITY];
            }
            case PlanningFactors.FACILITY: {
                return [WidgetCategoryEnum.FACILITY, WidgetCategoryEnum.BUDGET];
            }
            default:
                return [WidgetCategoryEnum.FACILITY, WidgetCategoryEnum.BUDGET];
        }
    };
    const determineBudgetWidget = (budgetType) => {
        switch (budgetType) {
            case BudgetTypeEnum.FUNDING_TYPE: {
                return WidgetType.FUNDING_TYPE;
            }
            case BudgetTypeEnum.TOTAL_BUDGET: {
                return WidgetType.TOTAL_BUDGET;
            }
            case BudgetTypeEnum.NEEDS: {
                return WidgetType.NEED;
            }
            case BudgetTypeEnum.FUNDING_TYPE_PER_YEAR: {
                return WidgetType.FUNDING_TYPE_YEAR;
            }
            default:
                return WidgetType.TOTAL_BUDGET;
        }
    };
    const determineFacilityWidget = () => {
        return WidgetType.FACILITY;
    };
    const determineWidgetTypes = (planningFactor, budgetType) => {
        const widgets = [];
        const widgetOrder = determineWidgetOrder(planningFactor);
        widgetOrder.forEach((widgetCategory) => {
            switch (widgetCategory) {
                case WidgetCategoryEnum.BUDGET: {
                    if (budgetType === BudgetTypeEnum.FUNDING_TYPE_PER_YEAR) {
                        widgets.push(determineBudgetWidget(budgetType));
                        widgets.push(WidgetType.FUNDING_TYPE);
                    }
                    else {
                        widgets.push(determineBudgetWidget(budgetType));
                        widgets.push(WidgetType.FUNDING_TYPE_YEAR);
                    }
                    break;
                }
                case WidgetCategoryEnum.FACILITY: {
                    widgets.push(determineFacilityWidget());
                }
            }
        });
        setWidgetTypes(widgets);
    };
    const getTitle = (widgetType) => {
        switch (widgetType) {
            case WidgetType.TOTAL_BUDGET:
                return 'Budget Summary';
            case WidgetType.FUNDING_TYPE:
                return 'Budget Summary';
            case WidgetType.FACILITY:
                return 'Total budget per facility';
            case WidgetType.FUNDING_TYPE_YEAR:
                return 'Fund by Year';
            case WidgetType.PERCENTAGE_AND_BUDGET:
                return 'Priority by %';
            case WidgetType.NEED:
                return 'Budget Summary';
        }
    };
    useEffect(() => {
        determineWidgetTypes(planningFactor, budgetType);
    }, [planningFactor, budgetType]);
    const widgetRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const widgetResizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
        });
    });
    useEffect(() => {
        if (widgetRef.current) {
            widgetResizeObserver.observe(widgetRef.current);
        }
        return () => {
            widgetRef.current && widgetResizeObserver.unobserve(widgetRef.current);
            widgetResizeObserver.disconnect();
        };
    }, [widgetRef.current]);
    const getChunkSize = (data) => {
        if (data.length > 0 &&
            configs[index].widgetType === WidgetType.FUNDING_TYPE_YEAR) {
            return 10;
        }
        return data.length;
    };
    return (_jsxs(Grid, Object.assign({ h: '100%', w: '100%', templateColumns: '50px auto 50px', ref: widgetRef }, { children: [_jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, display: 'block', margin: 'auto', onClick: () => showPrevious() }, { children: _jsx(IconButton, Object.assign({ "aria-label": 'Left Arrow', variant: 'ghost', isDisabled: index === 0 }, { children: _jsx(ChevronLeftIcon, {}) })) })), _jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, w: '100%', maxH: '100%' }, { children: configs.length ? (_jsxs(VStack, Object.assign({ w: 'full', h: 'full' }, { children: [_jsxs(HStack, Object.assign({ w: 'full' }, { children: [_jsx(Text, { children: getTitle(configs[index].widgetType) }), _jsx(Spacer, {}), _jsxs(HStack, Object.assign({ spacing: 4 }, { children: [planData.totalEscalationImpact !== undefined && (_jsx(Box, Object.assign({ borderRadius: 2, bg: light_blue, py: 1, px: 2 }, { children: _jsxs(Text, Object.assign({ fontSize: 'xs', color: white }, { children: ["Escalation Impact", ' ', formatBudgetToString(planData.totalEscalationImpact)] })) }))), budgetType !== BudgetTypeEnum.NEEDS && (_jsx(Box, Object.assign({ borderRadius: 2, bg: blue, py: 1, px: 2 }, { children: _jsxs(Text, Object.assign({ fontSize: 'xs', color: white }, { children: ["Total Budget ", formatBudgetToString(planData.totalBudget)] })) }))), _jsx(Box, Object.assign({ borderRadius: 2, bg: secOrange, py: 1, px: 2 }, { children: _jsxs(Text, Object.assign({ fontSize: 'xs', color: gray }, { children: ["Total Allocated", ' ', formatBudgetToString(planData.totalAllocation)] })) }))] }))] })), _jsxs(Stack, Object.assign({ h: '100%', w: 'full', flexGrow: 1 }, { children: [configs[index].chartType === 'bar' &&
                                    configs[index].chartData !== undefined ? (_jsx(ScrollableBarChart, { data: (_b = (_a = configs[index].chartData) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : {}, config: (_d = (_c = configs[index].chartData) === null || _c === void 0 ? void 0 : _c.config) !== null && _d !== void 0 ? _d : {}, containerHeight: '100%', containerWidth: `${width}px`, chunkSize: getChunkSize((_f = (_e = configs[index].chartData) === null || _e === void 0 ? void 0 : _e.data.axisCategories) !== null && _f !== void 0 ? _f : []) })) : null, configs[index].chartType === 'pie' &&
                                    configs[index].pieChartData !== undefined ? (_jsx(PieChart, { data: (_h = (_g = configs[index].pieChartData) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : [], labels: (_k = (_j = configs[index].pieChartData) === null || _j === void 0 ? void 0 : _j.labels) !== null && _k !== void 0 ? _k : [], containerHeight: height, containerWidth: width, config: {
                                        tooltip: {
                                            enabled: (_p = (_o = (_m = (_l = configs[index].pieChartData) === null || _l === void 0 ? void 0 : _l.config) === null || _m === void 0 ? void 0 : _m.tooltip) === null || _o === void 0 ? void 0 : _o.enabled) !== null && _p !== void 0 ? _p : true,
                                            custom: (_s = (_r = (_q = configs[index].pieChartData) === null || _q === void 0 ? void 0 : _q.config) === null || _r === void 0 ? void 0 : _r.tooltip) === null || _s === void 0 ? void 0 : _s.custom,
                                        },
                                    } })) : null] }))] }))) : (_jsx(Center, Object.assign({ h: 'full' }, { children: 'Widget Not Available' }))) })), _jsx(GridItem, Object.assign({ rowSpan: 1, colSpan: 1, display: 'block', margin: 'auto', onClick: () => showNext() }, { children: _jsx(IconButton, Object.assign({ "aria-label": 'Right Arrow', variant: 'ghost', isDisabled: index === configs.length - 1 }, { children: _jsx(ChevronRightIcon, {}) })) }))] })));
};
export default WidgetContainer;
