import { useEffect, useState, useRef } from 'react';
/**
 * Hook for scrolling to specified anchor tag on a page and
 * providing the height of a sticky header element if applicable to use
 * as the scrollMarginTop value.
 *
 * @param defaultAnchor - The default anchor tag
 * @returns
 */
export const useAnchorScroll = (defaultAnchor) => {
    const [selectedAnchor, setSelectedAnchor] = useState(defaultAnchor);
    // The key would be the ID of the anchor element, and the value would be the DOM element
    const anchorSectionRefs = useRef({});
    const anchorHeaderRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [isAutoScrolling, setIsAutoScrolling] = useState(false);
    const isScrollEndSupported = 'onscrollend' in window;
    useEffect(() => {
        if (anchorHeaderRef.current && !height) {
            setHeight(anchorHeaderRef.current.offsetHeight);
        }
    });
    useEffect(() => {
        const handleAnchorHighlighting = () => {
            // We want to only run this logic when we are scrolling manually
            // We don't want to trigger this scroll event logic when changing the selected anchor section
            // as doing so would keep the scroll position at the selected section,
            // preventing us from scrolling anywhere.
            // We also run this if the scroll end event is not supported since the isAutoScrolling
            // flag is set in the scroll end event listener for browsers that support it.
            if (!isAutoScrolling || !isScrollEndSupported) {
                const anchorSections = Object.keys(anchorSectionRefs.current);
                if (anchorSections.length) {
                    for (let i = 0; i < anchorSections.length; i++) {
                        const anchor = anchorSectionRefs.current[anchorSections[i]];
                        if (anchorHeaderRef.current && anchor) {
                            const anchorHeaderBottom = anchorHeaderRef.current.offsetTop + height;
                            const anchorSectionY = anchor.offsetTop;
                            const anchorSectionHeight = anchor.offsetHeight;
                            if (anchorHeaderBottom < anchorSectionY + anchorSectionHeight) {
                                setSelectedAnchor({
                                    id: Object.keys(anchorSectionRefs)[i],
                                    index: i,
                                });
                                break;
                            }
                        }
                    }
                }
            }
        };
        const handleScrollEnd = () => {
            if (isAutoScrolling) {
                setIsAutoScrolling(false);
            }
        };
        window.addEventListener('scroll', handleAnchorHighlighting);
        window.addEventListener('scrollend', handleScrollEnd);
        return () => {
            window.removeEventListener('scroll', handleAnchorHighlighting);
            window.removeEventListener('scrollend', handleScrollEnd);
        };
    }, [isAutoScrolling]);
    const onAnchorClick = (index) => {
        setIsAutoScrolling(true);
        const selectedAnchorId = Object.keys(anchorSectionRefs.current)[index];
        const anchoredElement = document.getElementById(selectedAnchorId);
        if (anchoredElement) {
            anchoredElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setSelectedAnchor({
            id: Object.keys(anchorSectionRefs.current)[index],
            index,
        });
    };
    return {
        anchorHeaderRef,
        height,
        selectedAnchor,
        setSelectedAnchor,
        anchorSectionRefs,
        onAnchorClick,
    };
};
