import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex, FormControl, FormErrorMessage, FormLabel, VStack, } from '@chakra-ui/react';
import theme from '../../theme/theme';
const InputWrapper = ({ children, size = 'medium', isRequired = false, label, error, }) => {
    var _a, _b;
    const labelStyle = size === 'small'
        ? (_a = theme.textStyles) === null || _a === void 0 ? void 0 : _a.small.strong
        : (_b = theme.textStyles) === null || _b === void 0 ? void 0 : _b.body.strong;
    // despite being wrapped as a form control, that is just a grouping element
    // this does not require a form
    return (_jsxs(FormControl, Object.assign({ isRequired: isRequired, isInvalid: !!error, w: 'full' }, { children: [_jsxs(VStack, Object.assign({ gap: 1, align: 'start', w: 'full' }, { children: [label ? (_jsx(FormLabel, Object.assign({ color: 'primary.blue.500', sx: Object.assign({}, labelStyle), m: 0, variant: 'designSystem' }, { children: label }))) : null, _jsx(Flex, Object.assign({ id: 'Input', w: 'full' }, { children: children }))] })), error ? _jsx(FormErrorMessage, { children: error }) : null] })));
};
export default InputWrapper;
