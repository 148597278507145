var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { convertBlobToFile } from '../conversion/GeneralConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertPlanAssetDetailResponseToPlanAssetDetail, convertPlanResponseToIScenarioPlan, convertToLockUnlockRequest, } from '../models/Plan/PlanConversions';
import { convertIScenarioToCompareArrayToScenarioCompareRequest, convertPlanCompareResponseToIScenarioComparison, } from '../models/Comparison/ScenarioCompareConversions';
export const usePlanService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchScenarioPlan = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.getApiV1PlanLoad(scenarioIdentity), {
            error: {
                label: `Error occurred retrieving Plan for ScenarioId: ${scenarioIdentity}.`,
            },
            success: { enabled: false },
        })
            .then((res) => {
            return convertPlanResponseToIScenarioPlan(res);
        })
            .catch(() => {
            return undefined;
        });
    });
    const fetchAssets = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.getApiV1PlanGroup(orgIdentity), {
            error: { label: 'Error occurred retrieving Plan Assets.' },
            success: { enabled: false },
        })
            .then((res) => convertPlanAssetDetailResponseToPlanAssetDetail(res))
            .catch(() => undefined);
    });
    const schedulePlan = (plan) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.postApiV1PlanSchedule(plan), {
            error: { label: 'Error occurred scheduling the plan.' },
            success: {
                enabled: true,
                label: 'Scenario successfully updated',
            },
        })
            .then((res) => convertPlanResponseToIScenarioPlan(res))
            .catch(() => {
            return undefined;
        });
    });
    const lockUnlockScenario = (scenarioIdentity, isLocking, scenarioName) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.postApiV1PlanLock(convertToLockUnlockRequest(scenarioIdentity, isLocking)), {
            error: {
                label: `Error occurred while ${isLocking ? 'locking' : 'unlocking'} ScenarioName: ${scenarioName}.`,
            },
            success: {
                label: `Successfully ${isLocking ? 'locked' : 'unlocked'} ScenarioName: ${scenarioName}.`,
                enabled: true,
            },
        })
            .then(() => {
            return;
        })
            .catch(() => undefined);
    });
    const fetchTaxImpactCSV = (scenarioId, fileName, type) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.plan.getApiV1PlanExport(scenarioId), {
            error: {
                label: `Error occurred while downloading CSV for ScenarioId: ${scenarioId}.`,
            },
            success: {
                label: `Successfully downloaded CSV for ScenarioId: ${scenarioId}.`,
                enabled: true,
            },
        })
            .then((res) => {
            return convertBlobToFile(res, fileName, type);
        })
            .catch(() => undefined);
    });
    const fetchComparison = (scenarios) => __awaiter(void 0, void 0, void 0, function* () {
        const scenarioCompareRequest = convertIScenarioToCompareArrayToScenarioCompareRequest(scenarios);
        return handlePlatformResponse(platformApi.plan.postApiV1PlanCompare(scenarioCompareRequest)).then((res) => convertPlanCompareResponseToIScenarioComparison(res));
    });
    return {
        plan: {
            fetchScenarioPlan,
            fetchAssets,
            fetchComparison,
            schedulePlan,
            lockUnlockScenario,
            fetchTaxImpactCSV,
        },
    };
};
