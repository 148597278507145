var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Flex, HStack, Spacer, Switch, Text, useDisclosure, useToken, VStack, } from '@chakra-ui/react';
import CustomSelect from '@frontend/design-system/components/Inputs/CustomSelect';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { variants } from '@frontend/design-system/theme/theme';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { sortAssets } from '../../../../config/CPHelperFunctions';
import { useFloorPlan } from '../../../../contexts/FloorPlans/FloorPlanHook';
import { useProjectsContext } from '../../../../contexts/Projects/ProjectsContext';
import services from '../../../../services';
import { toBlobStoreContainerUrl } from '../../../../utils/fileUtils';
import { uniqueObjects } from '../../../../utils/sortingAndFiltering';
import { AssetMap, MapLegend } from '../../assets/AssetMap';
import { ProjectAssetsView } from '../../ProjectDetails/ProjectAssetsView';
import AssetsModal from '../AssetsModal';
import { AssociatedProjects } from './AssociatedProjects';
export const FloorPlanRow = ({ plan, assets, }) => {
    return (_jsxs(Flex, Object.assign({ alignItems: 'center', gap: 2 }, { children: [_jsx(Text, Object.assign({ noOfLines: 1 }, { children: plan.name })), (assets === null || assets === void 0 ? void 0 : assets.get(plan.identity)) ? (_jsx(Box, { backgroundColor: 'orange', minW: 2, minH: 2, h: 2, w: 2, borderRadius: 50 })) : null] })));
};
export const AssociationsTab = ({ orgIdentity, assets, siteName, facilityName, facilityIdentity, editMode, assignedAssetIds, updateAssignedAssets, showAgeAdjustedCondition, projectUseForm, isLoadingAssetLocations, project, }) => {
    const { isOpen: isAssetsModalOpen, onOpen: onAssetsModalOpen, onClose: onAssetsModalClose, } = useDisclosure();
    const [blue] = useToken('colors', ['brand.primBlue']);
    const { projectList } = useProjectsContext();
    const [mapMode, setMapMode] = useState(false);
    const [width, setWidth] = useState(0);
    const floorplanRef = useRef(null);
    const [imgUrl, setImgUrl] = useState('');
    useLayoutEffect(() => {
        if (floorplanRef.current && mapMode) {
            const { width } = floorplanRef.current.getBoundingClientRect();
            setWidth(width);
        }
    }, [floorplanRef.current, mapMode]);
    const resizeObserver = new ResizeObserver((entries) => {
        entries[0].contentRect && setWidth(entries[0].contentRect.width);
    });
    useEffect(() => {
        if (floorplanRef.current) {
            resizeObserver.observe(floorplanRef.current);
        }
        return () => {
            floorplanRef.current && resizeObserver.unobserve(floorplanRef.current);
            resizeObserver.disconnect();
        };
    }, [floorplanRef.current]);
    const { floorPlanList: currentPlans } = useFloorPlan(facilityIdentity);
    const [selectedPlan, setSelectedPlan] = useState();
    const [assetsOnPlan, setAssetsOnPlan] = useState(new Map());
    const [assignedAssetCache, setAssignedAssetCache] = useState([]);
    const [assignedAssets, setAssignedAssets] = useState([]);
    useEffect(() => {
        if (currentPlans.length > 0) {
            let activePlan = currentPlans[0];
            for (const plan of currentPlans) {
                const assetOnPlan = assets.find((asset) => {
                    return (asset.floorplanIdentity === plan.identity &&
                        assignedAssetIds.includes(asset.identity));
                });
                if (assetOnPlan !== undefined) {
                    activePlan = plan;
                    break;
                }
            }
            setSelectedPlan(activePlan);
        }
    }, [currentPlans, assets]);
    useEffect(() => {
        const planMap = new Map();
        const temporaryAssignedAssetIds = assignedAssets.map((asset) => asset.identity);
        for (const plan of currentPlans) {
            const assetOnPlan = assets.find((asset) => {
                return (asset.floorplanIdentity === plan.identity &&
                    temporaryAssignedAssetIds.includes(asset.identity));
            });
            planMap.set(plan.identity, assetOnPlan !== undefined);
        }
        setAssetsOnPlan(planMap);
    }, [currentPlans, assets, assignedAssets]);
    useEffect(() => {
        const assignedAssets = assignedAssetIds.flatMap((id) => assets.filter((asset) => asset.identity === id));
        setAssignedAssetCache(sortAssets(assignedAssets, showAgeAdjustedCondition));
    }, [assets, assignedAssetIds]);
    useEffect(() => {
        setAssignedAssets(assignedAssetCache);
    }, [assignedAssetCache]);
    const getPhotos = (assetId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const assetPhotos = yield services.photoService.getPhotosByOrg(orgIdentity, [assetId]);
            return assetPhotos;
        }
        catch (err) {
            console.error(`Error occurred fetching photos, ${err}`);
            return [];
        }
    });
    const onSelectHandler = (asset) => {
        const newAssignedAssets = [...assignedAssets];
        const assetIndex = newAssignedAssets.findIndex((assignedAsset) => assignedAsset.identity === asset.identity);
        if (assetIndex !== -1) {
            newAssignedAssets.splice(assetIndex, 1);
        }
        else {
            newAssignedAssets.push(asset);
        }
        setAssignedAssets(sortAssets(newAssignedAssets, showAgeAdjustedCondition));
    };
    const onCloseHandler = () => {
        onAssetsModalClose();
        setAssignedAssets(assignedAssetCache);
    };
    const onSubmitHandler = () => {
        updateAssignedAssets(assignedAssets.map((asset) => asset.identity));
        setAssignedAssetCache(assignedAssets);
        toast({
            title: 'You must save the project to associate the assets to the project',
            status: 'warning',
            isClosable: true,
        });
        onAssetsModalClose();
    };
    const onSelectAllHandler = (selectAll, filteredAssets) => {
        if (selectAll) {
            setAssignedAssets((prev) => {
                return uniqueObjects([...prev, ...sortAssets(filteredAssets, showAgeAdjustedCondition)], (asset) => asset.identity);
            });
        }
        else {
            // TODO: confirm if this is the correct behavior
            setAssignedAssets((prev) => [...prev].filter((asset) => !filteredAssets
                .map((asset) => asset.identity)
                .includes(asset.identity)));
        }
    };
    useEffect(() => {
        const loadImageUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const imageUrl = toBlobStoreContainerUrl((_a = selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.planFileName) !== null && _a !== void 0 ? _a : '');
            imageUrl
                ? setImgUrl(imageUrl)
                : toast({
                    title: 'Image Failure',
                    description: 'Could not pull up plan image.',
                    status: 'error',
                    isClosable: true,
                });
        });
        loadImageUrl();
    }, [selectedPlan]);
    return (_jsxs(HStack, Object.assign({ p: '40px 45px 40px 45px', justify: 'space-between', spacing: '10%', align: 'flex-start' }, { children: [_jsx(VStack, Object.assign({ width: '50%', justifyItems: 'left', mb: '30px' }, { children: _jsxs(VStack, Object.assign({ w: 'full', ref: floorplanRef }, { children: [_jsxs(HStack, Object.assign({ w: 'full' }, { children: [_jsx(Text, Object.assign({ color: blue, fontWeight: 'semibold' }, { children: "Associated Assets" })), _jsx(Spacer, {}), editMode ? (_jsx(Button, Object.assign({ leftIcon: _jsx(MdEdit, { fontSize: '18px' }), variant: variants.blueOutlineBtn, onClick: () => {
                                        onAssetsModalOpen();
                                    }, isDisabled: isLoadingAssetLocations }, { children: "Edit Assets" }))) : null] })), currentPlans.length ? (_jsxs(HStack, Object.assign({ w: 'full' }, { children: [mapMode ? (_jsx(CustomSelect, { options: currentPlans, onSelectOption: setSelectedPlan, defaultOption: selectedPlan, renderSelection: (option) => (_jsx(FloorPlanRow, { plan: option, assets: assetsOnPlan })), renderOption: (option) => (_jsx(FloorPlanRow, { plan: option, assets: assetsOnPlan })) })) : null, _jsx(Spacer, {}), _jsx(Text, Object.assign({ color: blue }, { children: "Map View" })), _jsx(Switch, { isChecked: mapMode, variant: 'baseStyle', size: 'md', onChange: () => setMapMode((prev) => !prev) })] }))) : null, mapMode ? (_jsxs(VStack, Object.assign({ alignItems: 'flex-end', position: 'relative' }, { children: [_jsx(MapLegend, {}), _jsx(AssetMap, { containerWidth: width, assets: assets.filter((asset) => asset.floorplanIdentity === (selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.identity)), assignedAssets: assignedAssets, planImg: imgUrl }), _jsx(LoadingWithControl, { isLoading: isLoadingAssetLocations })] }))) : (_jsxs(VStack, Object.assign({ h: '375px', overflow: 'auto', w: 'full', position: 'relative' }, { children: [_jsx(ProjectAssetsView, { loadImages: getPhotos, assets: assignedAssets, showAgeAdjustedCondition: showAgeAdjustedCondition, actionText: `Tie assets from the FCA tool to the project. To associate assets, ${editMode
                                        ? 'click on the edit assets button'
                                        : 'edit the project'}.` }), _jsx(LoadingWithControl, { isLoading: isLoadingAssetLocations })] })))] })) })), _jsx(VStack, Object.assign({ width: '50%', justifyItems: 'left', mb: '30px' }, { children: _jsx(AssociatedProjects, { project: project, allProjects: projectList || [], isEditing: editMode, projectUseForm: projectUseForm }) })), _jsx(AssetsModal, { isOpen: isAssetsModalOpen, onClose: onCloseHandler, onSubmit: onSubmitHandler, assets: assets, assetsOnPlan: assetsOnPlan, siteName: siteName, facilityName: facilityName, facilityIdentity: facilityIdentity, assignedAssets: assignedAssets, getPhotos: getPhotos, showAgeAdjustedCondition: showAgeAdjustedCondition, editMode: editMode, mapMode: mapMode, setMapMode: setMapMode, onSelect: onSelectHandler, onSelectAll: onSelectAllHandler, onSelectPlan: setSelectedPlan, selectedPlan: selectedPlan, currentPlans: currentPlans, imgUrl: imgUrl })] })));
};
