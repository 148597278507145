import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterInput, FilterInputType, } from '@frontend/design-system/components/Filter/FilterInput';
import { Form } from '@frontend/design-system/components/FormModal/FormModal';
import { validateRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { priorityColorsCodes } from '../../config/constants';
//TODO: Find better place for this helper method (used in other apps)
/**
 * Return a list of unique values for a field within object T
 * @param items list of objects to get unique values from
 * @param field field on object to get the unique values from
 * @param emptyValue allows an empty value to be considered a unique value in list
 * @returns list of unique values
 */
export const uniqueFieldValuesFromObjectList = (items, field, emptyValue) => {
    const uniqueValues = new Set();
    items === null || items === void 0 ? void 0 : items.forEach((item) => {
        const val = field(item);
        if (val === null ||
            val === undefined ||
            (typeof val === 'string' && val === '')) {
            if (emptyValue !== undefined) {
                uniqueValues.add(emptyValue);
            }
        }
        else if (Array.isArray(val)) {
            val.forEach((item) => uniqueValues.add(item));
        }
        else {
            uniqueValues.add(val);
        }
    });
    return Array.from(uniqueValues);
};
export const ProjectFilterModal = ({ allProjects, disclosure, filterContext, }) => {
    const { filter, applyFilter, initialFilter: emptyFilter } = filterContext;
    const { handleSubmit, control, reset, formState: { isValid }, } = useForm({
        values: filter,
        mode: 'onChange',
    });
    useEffect(() => {
        if (disclosure.isOpen) {
            reset(filter);
        }
    }, [disclosure.isOpen]);
    return (_jsxs(Form.Modal, Object.assign({ disclosure: disclosure, onApply: handleSubmit(applyFilter), onClear: () => {
            reset(emptyFilter);
        }, labels: {
            title: 'Filters',
            cancel: 'Clear All',
            apply: 'Apply Filters',
        }, applyDisabled: !isValid }, { children: [_jsxs(Form.Column, Object.assign({ stackProps: { flexBasis: 1 } }, { children: [_jsx(FilterInput, { inputConfig: {
                            label: 'Facility',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'facilityName',
                            options: uniqueFieldValuesFromObjectList(allProjects, (project) => project.facilityName).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Project Type',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'projectTypeName',
                            options: uniqueFieldValuesFromObjectList(allProjects, (project) => project.projectTypeName).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Fund Type',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'plannedFundingTypes',
                            options: uniqueFieldValuesFromObjectList(allProjects, (project) => [
                                ...project.plannedFundingTypes,
                                project.fundingTypeName,
                            ]).map((item) => ({ label: item, value: item })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Priority',
                            type: FilterInputType.MULTI_SELECT,
                            formField: 'priorityRating',
                            options: priorityColorsCodes.map((codes) => ({
                                label: codes.code,
                                value: codes.enumCode,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Project Name',
                            type: FilterInputType.TEXT,
                            formField: 'name',
                        }, control: control })] })), _jsxs(Form.Column, Object.assign({ stackProps: { flexBasis: 1 } }, { children: [_jsx(FilterInput, { control: control, inputConfig: {
                            label: 'Budget',
                            type: FilterInputType.RANGE,
                            formField: 'estimatedBudget',
                            inputControl: {
                                format: (value) => {
                                    if (value !== undefined) {
                                        const currencyFormatter = new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 0,
                                        });
                                        return currencyFormatter.format(value);
                                    }
                                    else {
                                        return '';
                                    }
                                },
                                parse: (value) => {
                                    if (value !== undefined) {
                                        // needed to parse out $ from formatter
                                        const parsed = value.replace(/[^0-9.]/g, '');
                                        if (parsed !== '') {
                                            return parseInt(parsed);
                                        }
                                    }
                                    return undefined;
                                },
                            },
                            validate: (value) => {
                                if (value !== null) {
                                    const range = value;
                                    return validateRangeValue(range);
                                }
                            },
                        } }), _jsx(FilterInput, { inputConfig: {
                            label: 'Prioritization Criteria',
                            type: FilterInputType.MULTI_SELECT_AND,
                            formField: 'criteriaScores',
                            subField: 'name',
                            options: uniqueFieldValuesFromObjectList(allProjects, (project) => project.criteriaScores.map((scores) => scores.name)).map((item) => ({
                                label: item,
                                value: item,
                            })),
                        }, control: control }), _jsx(FilterInput, { inputConfig: {
                            label: 'Criteria Score',
                            type: FilterInputType.RANGE,
                            formField: 'criteriaScores',
                            subField: 'score',
                            inputControl: {
                                parse: (value) => {
                                    const MAX_CRITERIA_SCORE = 5;
                                    if (value !== undefined) {
                                        const number = parseInt(value);
                                        if (isNaN(number)) {
                                            return undefined;
                                        }
                                        return number > MAX_CRITERIA_SCORE ? 5 : number;
                                    }
                                    return undefined;
                                },
                            },
                            validate: (value) => {
                                if (value) {
                                    const range = value.get('score');
                                    if (range) {
                                        return validateRangeValue(range);
                                    }
                                }
                            },
                        }, control: control })] }))] })));
};
