export const truncateText = (text, maxChars) => {
    return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
};
// Will not return decimals
// Always rounds up
export const getCurrencyNotation = (num, showCurrencySymbol = true, decimalsToDisplay) => {
    const symbol = showCurrencySymbol ? '$' : '';
    if (num <= 999) {
        const options = {};
        if (showCurrencySymbol) {
            options.style = 'currency';
            options.currency = 'USD';
        }
        return `${num.toLocaleString('en-US', options).split('.')[0]}`;
    }
    else if (num <= 999000) {
        const roundByK = parseFloat((num / 1000).toFixed(decimalsToDisplay || 2));
        return `${symbol}${roundByK}K`;
    }
    else if (num <= 999000000) {
        const roundByM = parseFloat((num / 1000000).toFixed(decimalsToDisplay || 2));
        return `${symbol}${roundByM}M`;
    }
    else if (num <= 999000000000) {
        const roundByB = parseFloat((num / 1000000000).toFixed(decimalsToDisplay || 2));
        return `${symbol}${roundByB}B`;
    }
    else {
        const roundByT = parseFloat((num / 1000000000000).toFixed(decimalsToDisplay || 2));
        return `${symbol}${roundByT}T`;
    }
};
/** Will return formated truncated number and will replace zero with empty string  */
export const truncateToNumber = (value, excludeZero = false) => {
    // ensure empty values or 0 do not appear in the labels
    if (excludeZero &&
        (value === undefined || value === '' || value === '0' || value === 0)) {
        return '';
    }
    const currency = typeof value === 'string'
        ? getCurrencyNotation(parseInt(value), false)
        : getCurrencyNotation(value, false);
    return currency;
};
