import { useToken } from '@chakra-ui/react';
export const usePriorityScoreColors = () => {
    // useToken should just be a type safe way of referencing the tokens
    // only sets immediate='primary.orange.500'
    const [immediate, high, moderate, low, longRange] = useToken('colors', [
        'primary.orange.500',
        'primary.orange.200',
        'primary.blue.200',
        'primary.blue.300',
        'primary.blue.500',
    ]);
    return {
        immediate: {
            token: immediate,
            var: getChakraColorVariableFromToken(immediate),
        },
        high: {
            token: high,
            var: getChakraColorVariableFromToken(high),
        },
        moderate: {
            token: moderate,
            var: getChakraColorVariableFromToken(moderate),
        },
        low: {
            token: low,
            var: getChakraColorVariableFromToken(low),
        },
        longRange: {
            token: longRange,
            var: getChakraColorVariableFromToken(longRange),
        },
    };
};
/**
 * TODO: When we move to V3
 * Can be replace with https://chakra-ui.com/docs/theming/overview#tokens
 * @param token
 * @returns
 */
const getChakraColorVariableFromToken = (token) => {
    const parsedToken = token.replaceAll('.', '-');
    return `var(--chakra-colors-${parsedToken})`;
};
export const nonBrandedColorsList = [
    getChakraColorVariableFromToken('unbranded.green.100'),
    getChakraColorVariableFromToken('unbranded.green.500'),
    getChakraColorVariableFromToken('unbranded.green.900'),
    getChakraColorVariableFromToken('unbranded.blue.300'),
    getChakraColorVariableFromToken('unbranded.blue.100'),
    getChakraColorVariableFromToken('unbranded.blue.500'),
    getChakraColorVariableFromToken('unbranded.purple.500'),
    getChakraColorVariableFromToken('unbranded.purple.300'),
    getChakraColorVariableFromToken('unbranded.pink.100'),
    getChakraColorVariableFromToken('unbranded.red.500'),
    getChakraColorVariableFromToken('unbranded.pink.300'),
    getChakraColorVariableFromToken('unbranded.neutral.100'),
    getChakraColorVariableFromToken('unbranded.pink.500'),
    getChakraColorVariableFromToken('unbranded.brown.500'),
    getChakraColorVariableFromToken('unbranded.yellow.500'),
    getChakraColorVariableFromToken('unbranded.yellow.100'),
];
