import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, VStack, } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { TextAreaInput } from '@frontend/design-system/components/DynamicInput/TextAreaInput';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ScenarioListDropdown } from '../ScenarioListDropdown';
import { IncludeInCompare } from './IncludeInCompare';
export const CompareModal = ({ isOpen, scenarioList, scenarioId, canEdit, onSubmit, onCancel, }) => {
    const comparisonForm = {
        base: undefined,
        comparison: undefined,
        options: {
            totalFundingComparison: {
                label: 'Total funding dollars allocated per year',
                isActive: true,
            },
            projectTypeComparison: {
                label: 'Funding Dollars allocated by Project Type',
                isActive: true,
            },
            projectListComparison: { label: 'Project list', isActive: true },
            priorityByFacilityComparison: {
                label: 'Priority By Facility',
                isActive: true,
            },
        },
    };
    const form = useForm({
        defaultValues: comparisonForm,
        mode: 'onChange',
    });
    const { handleSubmit, setValue } = form;
    useEffect(() => {
        var _a;
        const scenarioToCompare = scenarioList === null || scenarioList === void 0 ? void 0 : scenarioList.find((scenario) => scenario.scenarioId === scenarioId);
        if (!scenarioToCompare)
            return;
        // Initializing the form with the selected scenario
        // If we ever support "re-compare" functionality, we might consider form.reset()
        setValue('base.description', (_a = scenarioToCompare === null || scenarioToCompare === void 0 ? void 0 : scenarioToCompare.description) !== null && _a !== void 0 ? _a : '', {
            shouldValidate: true,
        });
        setValue('base.identity', scenarioToCompare === null || scenarioToCompare === void 0 ? void 0 : scenarioToCompare.scenarioId, {
            shouldValidate: true,
        });
    }, [scenarioList, scenarioId]);
    const baseScenarioId = form.watch('base.identity');
    const comparisonScenarioId = form.watch('comparison.identity');
    const onScenarioChangeHandler = (scenario, newScenarioId) => {
        var _a;
        const scenarioToCompare = scenarioList === null || scenarioList === void 0 ? void 0 : scenarioList.find((scenario) => scenario.scenarioId === newScenarioId);
        const scenarioKey = scenario === 'base' ? 'base' : 'comparison';
        setValue(`${scenarioKey}.description`, (_a = scenarioToCompare === null || scenarioToCompare === void 0 ? void 0 : scenarioToCompare.description) !== null && _a !== void 0 ? _a : '', {
            shouldValidate: true,
        });
    };
    const descriptionAccordion = (scenarioKey) => {
        return (_jsx(Box, Object.assign({ w: 'full', color: 'secondary.dark.500' }, { children: _jsxs(AccordionItem, Object.assign({ border: 'none' }, { children: [_jsxs(AccordionButton, Object.assign({ "data-testid": `storyDescriptionButton-${scenarioKey}`, _expanded: { fontWeight: 'Bold' }, justifyContent: 'space-between' }, { children: [_jsx(Body, Object.assign({ color: !canEdit ? 'secondary.dark.300' : '' }, { children: "What story do you want to tell about the scenario?" })), _jsx(AccordionIcon, {})] })), _jsxs(AccordionPanel, Object.assign({ "data-testid": `storyDescriptionPanel-${scenarioKey}` }, { children: [_jsx(Controller, { control: form.control, name: `${scenarioKey}.description`, render: ({ field }) => (_jsx(TextAreaInput, { label: '', data: field.value ? field.value : '', onInputChange: field.onChange, placeholder: 'Description', constraints: { maxLength: 1400 }, editMode: true, errorMessage: undefined, isDisabled: !canEdit })) }), _jsx(Body, Object.assign({ paddingTop: 4 }, { children: "Your story could include points like: What are the goals of this scenario? What is unique about it and most importantly, how does it help you meet the goals of your organization?" }))] }))] })) })));
    };
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onCancel, size: '4xl', closeOnEsc: false, closeOnOverlayClick: false }, { children: [_jsx(ModalOverlay, {}), _jsx(ModalContent, Object.assign({ borderRadius: 20, filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25))', fontWeight: 300, boxSizing: 'border-box', p: 4, overflow: 'auto' }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(ModalHeader, Object.assign({ color: 'secondary.dark.500', fontSize: 36, fontWeight: 400 }, { children: 'Compare Scenarios' })), _jsx(ModalBody, { children: _jsx(Accordion, Object.assign({ w: '100%', allowToggle: true }, { children: _jsxs(VStack, Object.assign({ spacing: 5 }, { children: [_jsxs(VStack, Object.assign({ bg: 'secondary.light.100', borderColor: 'secondary.dark.200', borderWidth: '1px', px: 5, py: 4, alignItems: 'start', w: 'full', gap: 4 }, { children: [_jsx(Controller, { control: form.control, name: 'base.identity', render: ({ field }) => {
                                                        return (_jsx(ScenarioListDropdown, { scenarioList: scenarioList.filter((scenario) => scenario.scenarioId !== comparisonScenarioId), scenarioIdentity: field.value, onChangeScenario: (e) => {
                                                                onScenarioChangeHandler('base', e);
                                                                field.onChange(e);
                                                            } }));
                                                    }, rules: {
                                                        required: true,
                                                    } }), baseScenarioId ? descriptionAccordion('base') : null] })), _jsxs(VStack, Object.assign({ bg: 'secondary.light.100', borderColor: 'secondary.dark.200', borderWidth: '1px', px: 5, py: 4, gap: 4, alignItems: 'start', w: 'full' }, { children: [_jsx(Controller, { control: form.control, name: 'comparison.identity', render: ({ field }) => {
                                                        return (_jsx(ScenarioListDropdown, { scenarioList: scenarioList.filter((scenario) => scenario.scenarioId !== baseScenarioId), scenarioIdentity: field.value, onChangeScenario: (e) => {
                                                                onScenarioChangeHandler('comparison', e);
                                                                field.onChange(e);
                                                            }, isDisabled: !baseScenarioId }));
                                                    } }), comparisonScenarioId
                                                    ? descriptionAccordion('comparison')
                                                    : null] })), _jsx(IncludeInCompare, { form: form })] })) })) }), _jsx(ModalFooter, Object.assign({ pt: 5 }, { children: _jsxs(Flex, Object.assign({ w: '100%' }, { children: [_jsx(Spacer, {}), _jsx(Button, { type: 'submit', text: 'Compare', isDisabled: !form.formState.isValid })] })) }))] })) }))] })));
};
