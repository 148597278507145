import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, IconButton, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp, MdArrowLeft, MdArrowRight, } from 'react-icons/md';
import { BarChart } from './BarChart';
export const ScrollableBarChart = ({ config, data, containerHeight, containerWidth, chunkSize, scrollIndex, setScrollIndex, }) => {
    var _a, _b, _c;
    const [index, setIndex] = useState(0);
    const [labelsColors, setLabelColors] = useState(((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) || []);
    useEffect(() => {
        var _a;
        // resetting on widget change
        setIndex(0);
        if (scrollIndex === undefined) {
            setScrollIndex && setScrollIndex(0);
        }
        setLabelColors(((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) || []);
    }, [data]);
    useEffect(() => {
        var _a;
        if ((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) {
            const newLabelColors = scrollIndex
                ? getDataChunk(scrollIndex, config.labelColors.xAxis)
                : getDataChunk(index, config.labelColors.xAxis);
            setLabelColors(newLabelColors);
        }
    }, [index, scrollIndex, (_b = config.labelColors) === null || _b === void 0 ? void 0 : _b.xAxis]);
    const totalBars = data.axisCategories.length;
    const getDataChunk = (index, list) => {
        const minIndex = index;
        const maxIndex = index + chunkSize;
        return list.slice(minIndex, maxIndex);
    };
    const scroll = (_c = config.scrollAmount) !== null && _c !== void 0 ? _c : 1;
    const prev = () => {
        if (scrollIndex !== undefined &&
            setScrollIndex &&
            scrollIndex - scroll >= 0) {
            setScrollIndex((prevIndex) => prevIndex - scroll);
        }
        else if (index - 1 >= 0) {
            setIndex((prevIndex) => prevIndex - scroll);
        }
    };
    const next = () => {
        if (scrollIndex !== undefined &&
            setScrollIndex &&
            scrollIndex + scroll <= totalBars - chunkSize) {
            setScrollIndex((prevIndex) => prevIndex + scroll);
        }
        else if (index + 1 <= totalBars - chunkSize) {
            setIndex((prevIndex) => prevIndex + scroll);
        }
    };
    const modifiedData = {
        axisCategories: getDataChunk(scrollIndex ? scrollIndex : index, data.axisCategories),
        datasets: data.datasets.map((dataset) => (Object.assign(Object.assign({}, dataset), { data: getDataChunk(scrollIndex ? scrollIndex : index, dataset.data) }))),
    };
    const iconButtonStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSize: '24px',
        size: '24px',
        variant: 'unstyled',
    };
    return (_jsxs(VStack, Object.assign({ alignItems: 'center', justifyContent: 'center', flexDirection: config.horizontal ? 'row-reverse' : 'column', height: containerHeight, width: '100%' }, { children: [_jsx(BarChart, { config: Object.assign(Object.assign({}, config), { labelColors: Object.assign(Object.assign({}, config.labelColors), { xAxis: labelsColors }) }), data: modifiedData, containerHeight: containerHeight, containerWidth: containerWidth }), _jsxs(HStack, Object.assign({ flexDirection: config.horizontal ? 'column' : 'row', hidden: totalBars <= chunkSize }, { children: [_jsx(IconButton, Object.assign({ onClick: prev, icon: config.horizontal ? (_jsx(MdArrowDropUp, { size: '24px' })) : (_jsx(MdArrowLeft, { size: '24px' })), "aria-label": 'previous', isDisabled: scrollIndex ? scrollIndex === 0 : index === 0 }, iconButtonStyles)), _jsx(IconButton, Object.assign({ onClick: next, icon: config.horizontal ? (_jsx(MdArrowDropDown, { size: '24px' })) : (_jsx(MdArrowRight, { size: '24px' })), "aria-label": 'next', isDisabled: scrollIndex
                            ? scrollIndex >= totalBars - chunkSize
                            : index >= totalBars - chunkSize }, iconButtonStyles))] }))] })));
};
