import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, GridItem, List, useToken, VStack } from '@chakra-ui/react';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import BodyStrong from '@frontend/design-system/components/Typography/BodyStrong/BodyStrong';
import Heading1 from '@frontend/design-system/components/Typography/Heading1/Heading1';
import Small from '@frontend/design-system/components/Typography/Small/Small';
import { CompareProjectsListItem } from './CompareProjectsListItem';
export const CompareProjectsList = ({ baseScenario, comparisonScenario, overlappingProjects, }) => {
    const [blue] = useToken('colors', ['primary.blue.500']);
    const renderDescriptor = (baseScenario, comparisonScenario) => {
        if (baseScenario && comparisonScenario) {
            return 'Projects in the top section are done in both scenarios. Projects in each column are present in only that scenario.';
        }
        else if (baseScenario) {
            return 'Projects in the scenario.';
        }
        else {
            return null;
        }
    };
    const hasOverlappingProjects = overlappingProjects
        ? Object.values(overlappingProjects).some((value) => value.length > 0)
        : false;
    return (_jsxs(VStack, Object.assign({ w: '100%', justifyContent: 'center', gap: 10, paddingX: 10 }, { children: [_jsxs(VStack, Object.assign({ gap: 1 }, { children: [_jsx(Heading1, Object.assign({ color: blue }, { children: "Projects List" })), _jsx(Body, Object.assign({ color: blue }, { children: renderDescriptor(baseScenario, comparisonScenario) }))] })), _jsxs(Grid, Object.assign({ gridTemplateColumns: comparisonScenario ? 'repeat(3, 1fr)' : '60%', gridTemplateRows: 'auto', justifyContent: 'center', position: 'relative', zIndex: 1, width: '100%' }, { children: [baseScenario && (_jsx(GridItem, Object.assign({ zIndex: 1 }, { children: _jsx(CompareProjectsListSection, { scenarioName: baseScenario.scenarioName, projects: baseScenario.uniqueProjects, hasUniqueProjects: baseScenario.uniqueProjects
                                ? Object.values(baseScenario.uniqueProjects).some((value) => value.length > 0)
                                : false, hasOverlappingProjects: hasOverlappingProjects }) }))), baseScenario && comparisonScenario && (_jsx(GridItem, Object.assign({ zIndex: 2, margin: -5 }, { children: _jsx(CompareProjectsListSection, { scenarioName: 'Common Projects', projects: overlappingProjects, hasUniqueProjects: false, hasOverlappingProjects: hasOverlappingProjects, isCommonSection: true }) }))), comparisonScenario && (_jsx(GridItem, Object.assign({ zIndex: 1 }, { children: _jsx(CompareProjectsListSection, { scenarioName: comparisonScenario.scenarioName, projects: comparisonScenario.uniqueProjects, hasUniqueProjects: comparisonScenario.uniqueProjects
                                ? Object.values(comparisonScenario.uniqueProjects).some((value) => value.length > 0)
                                : false, hasOverlappingProjects: hasOverlappingProjects }) })))] }))] })));
};
const CompareProjectsListSection = ({ scenarioName, projects, hasUniqueProjects, hasOverlappingProjects, isCommonSection = false, }) => {
    const [darkgray300, lightgray100, lightgray300, neutral] = useToken('colors', [
        'secondary.dark.300',
        'secondary.light.100',
        'secondary.light.300',
        'unbranded.neutral.100',
    ]);
    const defaultStyles = {
        backgroundColor: lightgray100,
        border: `1px solid ${lightgray300}`,
        height: '100%',
        borderRadius: '10px',
        paddingRight: '40px',
        paddingLeft: '40px',
        paddingTop: '20px',
        paddingBottom: '20px',
        alignItems: 'start',
        gap: 2.5,
    };
    const middleSectionStyles = {
        backgroundColor: neutral,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        zIndex: 2,
        paddingTop: '40px',
    };
    const sectionStyles = isCommonSection
        ? Object.assign(Object.assign({}, defaultStyles), middleSectionStyles) : defaultStyles;
    const getEmptyStateMessage = () => {
        if (isCommonSection) {
            return hasOverlappingProjects ? null : 'No common projects.';
        }
        else {
            if (!hasUniqueProjects && hasOverlappingProjects) {
                return 'All projects are in common.';
            }
            else if (!hasUniqueProjects) {
                return 'No projects available.';
            }
        }
        return null;
    };
    return (_jsxs(VStack, Object.assign({ style: Object.assign({}, sectionStyles), "data-testid": 'project-list-section' }, { children: [_jsx(BodyStrong, { children: scenarioName }), getEmptyStateMessage() ? (_jsx(Small, Object.assign({ color: darkgray300 }, { children: getEmptyStateMessage() }))) : (_jsx(List, Object.assign({ w: '100%', spacing: 2.5 }, { children: _jsx(CompareProjectsListItem, { scenarioProjects: projects }) })))] })));
};
