import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { CheckboxInput } from '../DynamicInput/CheckboxInput';
import { Form } from '../FormModal/FormModal';
import { RangeInput } from '../Inputs/RangeInput';
import TextInput from '../../../design-system/components/Inputs/Input';
export var FilterInputType;
(function (FilterInputType) {
    FilterInputType["SELECT"] = "select";
    FilterInputType["MULTI_SELECT_AND"] = "multi-select-and";
    FilterInputType["MULTI_SELECT"] = "multi-select";
    FilterInputType["CHECKBOX"] = "checkbox";
    FilterInputType["RANGE"] = "range";
    FilterInputType["TEXT"] = "text";
})(FilterInputType || (FilterInputType = {}));
const getFieldFilterFromNestedOptionFilter = (field, andFilter) => {
    if (field && andFilter) {
        const fieldFilter = andFilter.get(field);
        return fieldFilter ? fieldFilter : [];
    }
    return [];
};
const getFieldFilterFromNestedFilter = (field, andFilter) => {
    const fieldFilter = andFilter.get(field);
    return fieldFilter || null;
};
const removeFieldFilterFromAndOptionFilter = (field, nestedFilter) => {
    if (field && nestedFilter) {
        nestedFilter.delete(field);
        return nestedFilter.size !== 0
            ? new Map(nestedFilter)
            : null;
    }
    else {
        return null;
    }
};
const updateFieldFilterOnAndOptionFilter = (field, nestedFilter, newOptions) => {
    const newNestedFilter = nestedFilter
        ? nestedFilter
        : new Map();
    newNestedFilter.set(field, newOptions);
    return new Map(newNestedFilter);
};
export function FilterInput({ inputConfig, control, index, }) {
    const { field, fieldState: { error }, } = useController({
        name: inputConfig.formField,
        control,
        rules: {
            validate: (val) => inputConfig.validate && inputConfig.validate(val),
        },
    });
    switch (inputConfig.type) {
        case FilterInputType.SELECT:
            return (_jsx(Form.Dropdown, { label: inputConfig.label, selectFrom: inputConfig.options || [], onChange: (e) => {
                    if (inputConfig.customChange) {
                        inputConfig.customChange(e);
                    }
                    field.onChange(e);
                }, value: field.value, hidden: false }, `filter_input_dropdown_${index}`));
        case FilterInputType.MULTI_SELECT:
            return (_jsx(Form.MultiSelectDropdown, { isMulti: true, label: inputConfig.label, selectFrom: inputConfig.options || [], onChange: (e) => {
                    // needed to ensure removal of option result in null for the filter instead of []
                    // an empty list is considered an active filter, null is no filter
                    let mulitE = e; // we can assume this is MultiValue based on isMulti=true
                    if (mulitE && mulitE.length === 0) {
                        mulitE = null;
                    }
                    if (inputConfig.customChange) {
                        inputConfig.customChange(mulitE);
                    }
                    field.onChange(mulitE);
                }, value: field.value, useBasicStyles: true }, `filter_input_multi_dropdown_${index}`));
        case FilterInputType.MULTI_SELECT_AND: {
            if (inputConfig.subField) {
                const fieldValue = field.value;
                const inputValue = getFieldFilterFromNestedOptionFilter(inputConfig.subField, fieldValue);
                return (_jsx(Form.MultiSelectDropdown, { isMulti: true, label: inputConfig.label, selectFrom: inputConfig.options || [], onChange: (e) => {
                        // needed to ensure removal of option result in null for the filter instead of []
                        // an empty list is considered an active filter, null is no filter
                        const selectedOptions = e; // we can assume this is MultiValue based on isMulti=true
                        let tempFieldValue = null;
                        if (selectedOptions.length === 0) {
                            tempFieldValue =
                                removeFieldFilterFromAndOptionFilter(inputConfig.subField, fieldValue);
                        }
                        else {
                            tempFieldValue =
                                updateFieldFilterOnAndOptionFilter(inputConfig.subField || '', // should always have a value given above condition check
                                fieldValue, selectedOptions // MultiValue<Option> can assume direct cast in to Option[]
                                );
                        }
                        if (inputConfig.customChange) {
                            inputConfig.customChange(tempFieldValue);
                        }
                        field.onChange(tempFieldValue);
                    }, value: inputValue }, `filter_input_multi_dropdown_${inputConfig.formField}`));
            }
            else {
                // developer runtime error for improper input setup
                throw Error(`inputConfig.subField is required when using ${FilterInputType.MULTI_SELECT_AND.toString()} input`);
            }
        }
        case FilterInputType.CHECKBOX:
            return (_jsx(CheckboxInput, { label: inputConfig.label, position: 'left', editMode: true, data: field.value, onInputChange: (e) => {
                    if (inputConfig.customChange) {
                        inputConfig.customChange(e);
                    }
                    field.onChange(e);
                } }, `filter_input_checkbox_${index}`));
        case FilterInputType.RANGE: {
            let rangeValue;
            /**
             * The subfield value in the input config lets us know that the range
             * value is a part of a nested filter (which is a Map).
             *
             * If it's a part of a nested filter, we need to make sure that
             * we set the range input correctly before rendering it by extracting
             * the range from the current input value (the NestedFilter object)
             */
            if (inputConfig.subField && field.value) {
                const fieldValue = field.value;
                const inputValue = fieldValue
                    ? getFieldFilterFromNestedFilter(inputConfig.subField, fieldValue)
                    : null;
                rangeValue = inputValue ? inputValue : {};
            }
            else {
                rangeValue = field.value;
            }
            return (_jsx(RangeInput, { label: inputConfig.label, testId: inputConfig.testId, value: rangeValue, onChange: (e) => {
                    // needed to ensure removal of min and max values result in null for the filter
                    // an object with empty min and max values is considered an active filter, null is no filter
                    let rangeE = e.target.value;
                    if ([undefined, null, NaN].includes(rangeE === null || rangeE === void 0 ? void 0 : rangeE.min) &&
                        [undefined, null, NaN].includes(rangeE === null || rangeE === void 0 ? void 0 : rangeE.max)) {
                        rangeE = null;
                    }
                    if (inputConfig.customChange) {
                        inputConfig.customChange(rangeE);
                    }
                    if (inputConfig.subField) {
                        const updatedMap = new Map(field.value);
                        if (rangeE) {
                            updatedMap.set(inputConfig.subField, rangeE);
                            field.onChange(updatedMap);
                        }
                        else {
                            updatedMap.delete(inputConfig.subField);
                            // If there are no other keys in the map,
                            // set the map to null (which rangeE is)
                            // Else, update the field with the new map
                            if (!updatedMap.size) {
                                field.onChange(rangeE);
                            }
                            else {
                                field.onChange(updatedMap);
                            }
                        }
                    }
                    else {
                        field.onChange(rangeE);
                    }
                }, inputControl: inputConfig.inputControl, error: error === null || error === void 0 ? void 0 : error.message }, `filter_input_range_${index}`));
        }
        case FilterInputType.TEXT: {
            return (_jsx(TextInput, { size: 'medium', label: inputConfig.label, value: field.value || '', onChange: field.onChange, placeholder: 'Project Name' }, `filter_input_text_${index}`));
        }
    }
}
