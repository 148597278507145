import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Input as ChakraInput } from '@chakra-ui/react';
import InputWrapper from './InputWrapper';
// Do not use, only exported to be used by other Design System Inputs
export const InputAtom = ({ size = 'medium', type, value, onChange, isDisabled = false, placeholder, }) => {
    const height = size === 'small' ? 6 : 9;
    return (_jsx(ChakraInput, { h: height, variant: 'base', py: size === 'small' ? 1 : 2, type: type, value: value, onChange: onChange, isDisabled: isDisabled, placeholder: placeholder }));
};
const Input = ({ size, label, errorMessage, isValid, isRequired, value, placeholder, onChange, type = 'text', isDisabled = false, }) => {
    var _a;
    const isValidValue = (_a = isValid === null || isValid === void 0 ? void 0 : isValid(value)) !== null && _a !== void 0 ? _a : false;
    return (_jsx(InputWrapper, Object.assign({ label: label, isRequired: isRequired, size: size, error: isValidValue ? undefined : errorMessage }, { children: _jsx(InputAtom, { size: size, value: value, onChange: onChange, type: type, isDisabled: isDisabled, placeholder: placeholder }) })));
};
export default Input;
