import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Table as ChakraTable, TableContainer, Tbody, } from '@chakra-ui/react';
import { getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { default as React, useEffect, useLayoutEffect, useState } from 'react';
import { TableHeaders } from './components/Headers';
import { TableRow } from './components/Row';
import { TableAccordionProvider } from './TableAccordionContext';
export function Table({ data, columns, accordionAllowsMultiple, maxHeight, paddingX, header, initialSort, manualSort, columnVisibility, customOnSortingChange, dropdown, }) {
    var _a, _b;
    const [sorting, setSorting] = React.useState(initialSort ? initialSort : []);
    useEffect(() => {
        if (manualSort) {
            table.setSorting(manualSort);
        }
    }, [manualSort]);
    const anySeeMoreColumns = data.some((row) => row.seeMore !== undefined);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: (newSorting) => {
            setSorting(newSorting);
            customOnSortingChange === null || customOnSortingChange === void 0 ? void 0 : customOnSortingChange();
        },
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            columnVisibility,
        },
        initialState: {
            sorting: initialSort ? initialSort : [],
        },
    });
    const { rows } = table.getRowModel();
    const tableContainerRef = React.useRef(null);
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => tableContainerRef.current,
        overscan: 35,
        estimateSize: () => 74,
    });
    const { getVirtualItems, getTotalSize } = rowVirtualizer;
    // this padding tells the row virtualizer when we need to "load new rows"
    // onto the page and when we can get rid of old rows.
    const virtualRows = getVirtualItems();
    const paddingTop = virtualRows.length > 0 ? ((_a = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[0]) === null || _a === void 0 ? void 0 : _a.start) || 0 : 0;
    const paddingBottom = virtualRows.length > 0
        ? getTotalSize() - (((_b = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[virtualRows.length - 1]) === null || _b === void 0 ? void 0 : _b.end) || 0)
        : 0;
    const scrollToTop = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    };
    useEffect(() => {
        if (rows.length > 0) {
            scrollToTop();
        }
    }, [rows.length]);
    const openFirstRow = manualSort ? true : false;
    const [headerWidth, setHeaderWidth] = useState(document.body.clientWidth);
    const tableRef = React.useRef(null);
    useLayoutEffect(() => {
        const updateTableWidth = () => {
            if (tableRef.current) {
                setHeaderWidth(tableRef.current.clientWidth);
            }
        };
        // Set the initial width
        updateTableWidth();
        // Attach resize listener
        window.addEventListener('resize', updateTableWidth);
        return () => {
            window.removeEventListener('resize', updateTableWidth);
        };
    }, []);
    return (_jsx(TableAccordionProvider, Object.assign({ allowMultiple: accordionAllowsMultiple, rowsLength: rows.length, openFirstRow: openFirstRow }, { children: _jsxs(Box, Object.assign({ ref: tableContainerRef, maxH: maxHeight !== null && maxHeight !== void 0 ? maxHeight : '100vh', width: '100%', px: paddingX, paddingBottom: '20px', overflowY: 'auto' }, { children: [_jsx(Box, Object.assign({ width: headerWidth }, { children: header })), _jsx(TableContainer, Object.assign({ overflowX: 'unset', overflowY: 'unset', style: {
                        paddingTop,
                        paddingBottom,
                        fontSize: '14px',
                    } }, { children: _jsxs(ChakraTable, Object.assign({ ref: tableRef }, { children: [_jsx(TableHeaders, { table: table, hasSeeMoreColumn: anySeeMoreColumns }), _jsx(Tbody, { children: virtualRows.map((virtualRow) => {
                                    const row = rows[virtualRow.index];
                                    return (_jsx(TableRow, { data: row, hasSeeMoreColumns: anySeeMoreColumns, totalColumns: columns.length, dropdown: dropdown }, virtualRow.key));
                                }) })] })) }))] })) })));
}
