import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, useTheme, VStack } from '@chakra-ui/react';
import { ScrollableBarChart } from '@frontend/design-system/components/Graphs/apexcharts/ScrollableBarChart';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import Heading1 from '@frontend/design-system/components/Typography/Heading1/Heading1';
import { getGlobalMinMax } from '@frontend/design-system/utils/chartsUtils';
import { getCurrencyNotation, truncateToNumber, } from '@frontend/design-system/utils/uiUtils';
import React from 'react';
import { WidgetType } from '../../../contexts/Plans/WidgetHook';
export const CompareProjectType = ({ baseScenario, comparisonScenario, }) => {
    const theme = useTheme();
    const displayChart = () => {
        var _a, _b;
        let projectTypesNames = [];
        if (baseScenario) {
            projectTypesNames = baseScenario.projectTypeBudget.map((projectType) => projectType.projectType);
        }
        if (comparisonScenario) {
            const comparisonProjectTypes = comparisonScenario.projectTypeBudget.map((projectType) => projectType.projectType);
            projectTypesNames = [
                ...new Set([...projectTypesNames, ...comparisonProjectTypes]),
            ];
        }
        const baseTotalBudget = (baseScenario === null || baseScenario === void 0 ? void 0 : baseScenario.projectTypeBudget.reduce((sum, pt) => sum + ((pt === null || pt === void 0 ? void 0 : pt.allocatedAmount) || 0), 0)) || 0;
        const comparisonTotalBudget = (comparisonScenario === null || comparisonScenario === void 0 ? void 0 : comparisonScenario.projectTypeBudget.reduce((sum, pt) => sum + ((pt === null || pt === void 0 ? void 0 : pt.allocatedAmount) || 0), 0)) || 0;
        const referenceScenario = baseTotalBudget >= comparisonTotalBudget
            ? baseScenario
            : comparisonScenario;
        if (referenceScenario) {
            projectTypesNames.sort((a, b) => {
                var _a, _b;
                const budgetA = ((_a = referenceScenario.projectTypeBudget.find((pt) => pt.projectType === a)) === null || _a === void 0 ? void 0 : _a.allocatedAmount) || 0;
                const budgetB = ((_b = referenceScenario.projectTypeBudget.find((pt) => pt.projectType === b)) === null || _b === void 0 ? void 0 : _b.allocatedAmount) || 0;
                return budgetB - budgetA;
            });
        }
        const projectTypeDataset = [];
        if (baseScenario) {
            projectTypeDataset.push({
                name: baseScenario.scenarioName,
                data: projectTypesNames.map((projectType) => {
                    var _a;
                    return ((_a = baseScenario.projectTypeBudget.find((item) => item.projectType === projectType)) === null || _a === void 0 ? void 0 : _a.allocatedAmount) || 0;
                }),
            });
        }
        if (comparisonScenario) {
            projectTypeDataset.push({
                name: comparisonScenario.scenarioName,
                data: projectTypesNames.map((projectType) => {
                    var _a;
                    return ((_a = comparisonScenario.projectTypeBudget.find((item) => item.projectType === projectType)) === null || _a === void 0 ? void 0 : _a.allocatedAmount) || 0;
                }),
            });
        }
        const projectTypeData = {
            axisCategories: projectTypesNames,
            datasets: projectTypeDataset,
        };
        const { globalMax, globalMin } = getGlobalMinMax(projectTypeData);
        const config = {
            chartType: 'bar',
            widgetType: WidgetType.TOTAL_BUDGET,
            chartData: {
                config: {
                    tooltip: {
                        enabled: true,
                        shared: false,
                        intersect: true,
                    },
                    datasetColors: comparisonScenario
                        ? [
                            theme.colors.unbranded.green[900],
                            theme.colors.unbranded.blue[300],
                        ]
                        : [theme.colors.unbranded.green[900]],
                    dataLabels: {
                        enable: true,
                    },
                    barLabel: {
                        enabled: true,
                        position: 'top',
                        format: (value) => truncateToNumber(value, true),
                    },
                    legend: {
                        show: !!comparisonScenario,
                        datasetLabels: baseScenario
                            ? comparisonScenario
                                ? [baseScenario.scenarioName, comparisonScenario.scenarioName]
                                : [baseScenario.scenarioName]
                            : [],
                    },
                    formatters: {
                        min: globalMin,
                        max: globalMax,
                        yAxis: projectTypeData.datasets.length > 0
                            ? getCurrencyNotation
                            : (value) => {
                                if (value === 0 || value === 1 || value === 2) {
                                    return `$${value.toFixed(0)}`;
                                }
                                return `$${value.toFixed(1)}`;
                            },
                    },
                },
                data: projectTypeData,
            },
        };
        return (_jsx(ScrollableBarChart, { data: (_a = config.chartData.data) !== null && _a !== void 0 ? _a : {}, config: (_b = config.chartData.config) !== null && _b !== void 0 ? _b : {}, containerHeight: '100%', containerWidth: '100%', chunkSize: 10 }));
    };
    return (_jsxs(VStack, Object.assign({ w: '100%', display: 'flex', justifyContent: 'center', gap: 8 }, { children: [_jsxs(VStack, Object.assign({ gap: 1 }, { children: [_jsx(Heading1, Object.assign({ color: 'primary.blue.500' }, { children: "Project Type" })), _jsx(Body, Object.assign({ color: 'primary.blue.500' }, { children: "Total fund dollars allocated by the project type." }))] })), _jsx(HStack, Object.assign({ alignItems: 'stretch', w: '100%' }, { children: _jsx(Box, Object.assign({ paddingX: 8, w: '100%', h: '309px' }, { children: displayChart() })) }))] })));
};
