import { BudgetTypeEnum, PlanGoalEnum, PlanningFactors, } from './ScenarioInterfaces';
export const convertCreateScenarioToScenarioConfigurationRequest = (createScenario) => {
    return {
        scenarioId: createScenario.scenarioId,
        organizationId: createScenario.organizationId,
        scenarioName: createScenario.scenarioName,
        scenarioDescription: createScenario.scenarioDescription,
        planDuration: createScenario.planDuration,
        startYear: createScenario.startYear,
        planningNeed: createScenario.planningNeed,
        budgetType: createScenario.budgetType,
        planningFactors: createScenario.planningFactors,
        budgetByFundingType: createScenario.budgetByFundingType
            ? convertBudgetByFundingTypeToScenarioFundingTypeBudgetRequest(createScenario.budgetByFundingType)
            : undefined,
        lumpSumBudget: createScenario.lumpSumBudget,
        useEscalationFactor: createScenario.useEscalationFactor,
    };
};
export const convertBudgetByFundingTypeToScenarioFundingTypeBudgetRequest = (budgetByFundingType) => {
    return budgetByFundingType.map((budget) => {
        return {
            fundingTypeId: budget.fundingTypeId,
            budget: budget.budget,
            year: budget.year,
        };
    });
};
export const convertScenarioSummaryResponseToIScenarioSummary = (scenario) => {
    return {
        scenarioId: scenario.scenarioId,
        scenarioName: scenario.scenarioName,
        isLocked: scenario.isLocked,
        description: scenario.description,
        startYear: scenario.startYear,
        duration: scenario.duration,
    };
};
export const convertIScenarioDetailsToIScenarioSummary = (scenario) => {
    return {
        scenarioId: scenario.id,
        scenarioName: scenario.name,
        isLocked: scenario.isLocked,
        startYear: scenario.startYear,
        duration: scenario.planDuration,
    };
};
const convertPlanningNeedToEnum = (value) => {
    const isValidEnum = Object.values(PlanGoalEnum).includes(value);
    if (!isValidEnum) {
        console.warn('Invalid Planning Need:', value);
    }
    return value;
};
const convertBudgetTypeToEnum = (value) => {
    const isValidEnum = Object.values(BudgetTypeEnum).includes(value);
    if (!isValidEnum) {
        console.warn('Invalid Budget Type:', value);
    }
    return value;
};
const convertPlanningFactorToEnum = (value) => {
    const isValidEnum = Object.values(PlanningFactors).includes(value);
    if (!isValidEnum) {
        console.warn('Invalid Planning Factor:', value);
    }
    return value;
};
export const convertResponseToScenario = (scenario) => {
    var _a, _b;
    return {
        id: scenario.scenarioId,
        name: scenario.scenarioName,
        description: (_a = scenario.scenarioDescription) !== null && _a !== void 0 ? _a : undefined,
        planDuration: scenario.planDuration,
        startYear: scenario.startYear,
        planningNeed: convertPlanningNeedToEnum(scenario.planningNeed),
        budgetType: convertBudgetTypeToEnum(scenario.budgetType),
        planningFactors: convertPlanningFactorToEnum(scenario.planningFactors),
        budgetsByFundingType: convertScenarioFundingTypeBudgetResponseToIScenarioFundingTypeBudget(scenario.budgetsByFundingType),
        totalBudget: (_b = scenario.totalBudget) !== null && _b !== void 0 ? _b : undefined,
        applyEscalationFactor: scenario.applyEscalationFactor,
        isLocked: scenario.isLocked,
        taxImpact: scenario.taxImpact
            ? convertTaxImpactDetailResponseToITaxImpactDetails(scenario.taxImpact)
            : undefined,
    };
};
export const convertScenarioFundingTypeBudgetResponseToIScenarioFundingTypeBudget = (budgetsByFundingType) => {
    const fundingTypeBudgets = budgetsByFundingType.map((budget) => {
        var _a;
        return {
            fundingTypeId: budget.fundingTypeId,
            fundingTypeName: budget.fundingTypeName,
            budget: budget.budget,
            year: (_a = budget.year) !== null && _a !== void 0 ? _a : undefined,
        };
    });
    return fundingTypeBudgets;
};
export const convertTaxImpactDetailResponseToITaxImpactDetails = (taxImpact) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return {
        totalReinvestment: (_a = taxImpact.totalReinvestment) !== null && _a !== void 0 ? _a : undefined,
        contributionYear: (_b = taxImpact.contributionYear) !== null && _b !== void 0 ? _b : undefined,
        contribution: (_c = taxImpact.contribution) !== null && _c !== void 0 ? _c : undefined,
        revenueBonded: (_d = taxImpact.revenueBonded) !== null && _d !== void 0 ? _d : undefined,
        revenueBondedPercentageYear: (_e = taxImpact.revenueBondedPercentageYear) !== null && _e !== void 0 ? _e : undefined,
        revenueBondedPercentage: (_f = taxImpact.revenueBondedPercentage) !== null && _f !== void 0 ? _f : undefined,
        annualHomeValue: taxImpact.annualHomeValue,
        annualTaxImpact: taxImpact.annualTaxImpact,
        monthlyHomeValue: taxImpact.monthlyHomeValue,
        monthlyTaxImpact: taxImpact.monthlyTaxImpact,
        acreValue: taxImpact.acreValue,
        annualAcreValueTaxImpact: taxImpact.annualAcreValueTaxImpact,
        ag2SchoolStateAid: (_g = taxImpact.ag2SchoolStateAid) !== null && _g !== void 0 ? _g : undefined,
        longTermFacilityMaintenanceStateAid: (_h = taxImpact.longTermFacilityMaintenanceStateAid) !== null && _h !== void 0 ? _h : undefined,
        stateAidDescription: (_j = taxImpact.stateAidDescription) !== null && _j !== void 0 ? _j : undefined,
        stateAidAmount: (_k = taxImpact.stateAidAmount) !== null && _k !== void 0 ? _k : undefined,
    };
};
export const convertICreateTaxImpactToTaxImpactSetRequest = (taxImpact) => {
    return {
        scenarioId: taxImpact.scenarioId,
        totalReinvestment: taxImpact.totalReinvestment,
        contributionYear: taxImpact.contributionYear,
        contribution: taxImpact.contribution,
        revenueBonded: taxImpact.revenueBonded,
        revenueBondedPercentageYear: taxImpact.revenueBondedPercentageYear,
        revenueBondedPercentage: taxImpact.revenueBondedPercentage,
        annualHomeValue: taxImpact.annualHomeValue,
        annualTaxImpact: taxImpact.annualTaxImpact,
        monthlyHomeValue: taxImpact.monthlyHomeValue,
        monthlyTaxImpact: taxImpact.monthlyTaxImpact,
        acreValue: taxImpact.acreValue,
        annualAcreValueTaxImpact: taxImpact.annualAcreValueTaxImpact,
        ag2SchoolStateAid: taxImpact.ag2SchoolStateAid,
        longTermFacilityMaintenanceStateAid: taxImpact.longTermFacilityMaintenanceStateAid,
        stateAidDescription: taxImpact.stateAidDescription,
        stateAidAmount: taxImpact.stateAidAmount,
    };
};
