import { jsx as _jsx } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { PlannerInstructionsContainer } from '../PlannerInstructions';
export const ScenarioInstructions = ({ canAddProject, hasNoProjects, hasNoScenarios, canEdit, }) => {
    const getScenarioInstructions = () => {
        if (!canEdit) {
            if (hasNoScenarios) {
                return 'No Scenarios available';
            }
            else {
                return 'Select a Scenario to begin';
            }
        }
        if (canAddProject) {
            if (hasNoProjects) {
                return 'To create a scenario, add projects by clicking the "Add Project" button';
            }
            else {
                return 'Select a scenario or start creating a new scenario';
            }
        }
        else {
            return 'Projects are needed to create a scenario. To create a project, go to Organization Settings --> myPlanning and enter in values for funding type & project type';
        }
    };
    return (_jsx(VStack, Object.assign({ justifyContent: 'center', h: '60vh' }, { children: _jsx(PlannerInstructionsContainer, { instructions: getScenarioInstructions() }) })));
};
