import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, HStack, Switch, VStack, } from '@chakra-ui/react';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import { Controller } from 'react-hook-form';
const mapToForm = (form) => {
    const dataPoints = form.getValues().options;
    return Object.entries(dataPoints).map(([key, toggle]) => {
        return (_jsxs(HStack, Object.assign({ pt: 4, w: 'full', justifyContent: 'space-between' }, { children: [_jsx(Body, { children: toggle.label }), _jsx(Controller, { control: form.control, name: `options.${key}.isActive`, render: ({ field }) => (_jsx(Switch, { id: field.name, isChecked: field.value, onChange: field.onChange, "data-testid": key })) })] }), key));
    });
};
export const IncludeInCompare = ({ form, }) => {
    return (_jsx(VStack, Object.assign({ bg: 'secondary.light.100', borderColor: 'secondary.dark.200', borderWidth: '1px', py: 4, alignItems: 'start', w: 'full' }, { children: _jsxs(AccordionItem, Object.assign({ border: 'none', w: 'full', "data-testid": 'dataPoints' }, { children: [_jsxs(AccordionButton, Object.assign({ justifyContent: 'space-between', "data-testid": 'questionsAccordionButton' }, { children: [_jsx(Body, Object.assign({ color: 'primary.blue.500', textStyle: 'body.strong', textAlign: 'left' }, { children: "What data points can help support your story?" })), _jsx(AccordionIcon, {})] })), _jsx(AccordionPanel, Object.assign({ px: 10, "data-testid": 'questionPanel' }, { children: mapToForm(form) }))] })) })));
};
