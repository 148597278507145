import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, HStack, List, ListItem, useToken, } from '@chakra-ui/react';
import Small from '@frontend/design-system/components/Typography/Small/Small';
import SmallStrong from '@frontend/design-system/components/Typography/SmallStrong/SmallStrong';
import { usePriorityScoreColors } from '@frontend/shared/src/design/colors';
import React from 'react';
import { PriorityCode, priorityColorsCodes } from '../../../config/constants';
import { getColorForPriority } from '../../../config/CPHelperFunctions';
export const CompareProjectsListItem = ({ scenarioProjects, }) => {
    const [blue, darkgray200, darkgray500] = useToken('colors', [
        'primary.blue.500',
        'secondary.dark.200',
        'secondary.dark.500',
    ]);
    const priorityColors = usePriorityScoreColors();
    const facilities = scenarioProjects ? Object.keys(scenarioProjects) : [];
    return (_jsx(Accordion, Object.assign({ defaultIndex: facilities.map((_, i) => i), allowMultiple: true }, { children: facilities
            .sort((a, b) => {
            return a.localeCompare(b, 'en-u-kn-true');
        })
            .map((facility, index) => {
            const sortedProjects = scenarioProjects
                ? scenarioProjects[facility].sort((a, b) => {
                    var _a, _b;
                    const priorityOrder = priorityColorsCodes.map((priority) => priority.enumCode);
                    const priorityComparison = priorityOrder.indexOf((_a = b.priority) !== null && _a !== void 0 ? _a : PriorityCode.NEEDS_ACTION) -
                        priorityOrder.indexOf((_b = a.priority) !== null && _b !== void 0 ? _b : PriorityCode.NEEDS_ACTION);
                    if (priorityComparison !== 0) {
                        return priorityComparison;
                    }
                    if (a.estimatedBudget === undefined &&
                        b.estimatedBudget === undefined) {
                        return 0;
                    }
                    if (a.estimatedBudget === undefined) {
                        return 1;
                    }
                    if (b.estimatedBudget === undefined) {
                        return -1;
                    }
                    return b.estimatedBudget - a.estimatedBudget;
                })
                : [];
            return (_jsx(React.Fragment, { children: sortedProjects.length > 0 ? (_jsxs(AccordionItem, Object.assign({ border: 'none', mb: 4 }, { children: [_jsx(AccordionButton, Object.assign({ padding: 0, mb: 1 }, { children: _jsxs(HStack, Object.assign({ flex: '1', justifyContent: 'space-between' }, { children: [_jsx(SmallStrong, Object.assign({ textTransform: 'uppercase', "data-testid": 'facility' }, { children: facility })), _jsx(AccordionIcon, { color: blue })] })) })), _jsx(Divider, { borderColor: darkgray200, mb: 1 }), _jsx(AccordionPanel, Object.assign({ padding: 0 }, { children: _jsx(List, Object.assign({ spacing: 1 }, { children: sortedProjects.map((project, index) => {
                                    const priorityColor = getColorForPriority(project.priority, priorityColors);
                                    const priorityColorBorder = priorityColor === 'transparent'
                                        ? darkgray500
                                        : priorityColor;
                                    return (_jsxs(ListItem, Object.assign({ gap: 2.5, display: 'flex' }, { children: [_jsx(Box, { bg: priorityColor, width: '12px', height: '12px', minWidth: '12px' // to not shrink
                                                , minHeight: '12px' // to not shrink
                                                , borderRadius: '50%', border: `1px solid ${priorityColorBorder}`, "data-testid": 'priority' }), _jsx(Small, Object.assign({ wordBreak: 'break-word', "data-testid": 'project' }, { children: project.name }))] }), `${index}-project`));
                                }) })) }))] }))) : null }, `${index}-accordion-item`));
        }) })));
};
